import { FC, useMemo } from 'react';
import { makeStyles } from '@material-ui/styles';
import { alpha, CardMedia, IconButton, Stack, Theme, Tooltip } from '@material-ui/core';
import AspectRatio from '@material-ui/icons/AspectRatio';
import DeleteForever from '@material-ui/icons/DeleteForever';

const useStyles = makeStyles((theme: Theme) => ({
    cardMedia: {
        position: 'relative',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        '&:hover': {
            '& > img.hoverable': {
                backdropFilter: 'brightness(70%)',
                filter: 'brightness(70%) blur(2px)'
            },
            '& > div': {
                opacity: 1,
                visibility: 'visible',
                transform: 'translateY(0)'
            }
        }
    },
    cardImage: {
        borderRadius: theme.shape.borderRadius,
        maxWidth: '600px',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        objectFit: 'contain',
        objectPosition: 'center',
        background: theme.palette.background.default,
        height: theme.spacing(30),
        outline: 'none',
        transition: '0.3s'
    },
    cardButtons: {
        position: 'absolute',
        background: alpha(theme.palette.background.default, 0.7),
        borderRadius: theme.shape.borderRadius,
        padding: '5px',
        opacity: 0,
        transform: 'translateY(-10px)',
        visibility: 'hidden',
        transition: '0.3s'
    }
}));

const ImagePreview: FC<{
    file?: File | Blob;
    url?: string;
    onResize?: () => void;
    onDelete?: () => void;
}> = ({ file, url, onResize, onDelete }) => {
    const classes = useStyles();

    const preview = useMemo(() => {
        if (url) {
            return url;
        }

        if (file) {
            return URL.createObjectURL(file);
        }

        return '';
    }, [file, url]);

    return (
        <CardMedia className={classes.cardMedia}>
            <img src={preview} className={`${classes.cardImage} ${onResize || onDelete ? 'hoverable' : ''}`} alt="" />
            {onResize || onDelete ? (
                <Stack className={classes.cardButtons} direction="row" alignItems="center" spacing={2} justifyContent="start">
                    {onResize && (
                        <Tooltip title="Редактировать изображение">
                            <IconButton size="large" color="primary" aria-label="edit image" onClick={onResize}>
                                <AspectRatio />
                            </IconButton>
                        </Tooltip>
                    )}
                    {onDelete && (
                        <Tooltip title="Удалить изображение">
                            <IconButton size="large" color="error" aria-label="delete image" onClick={onDelete}>
                                <DeleteForever />
                            </IconButton>
                        </Tooltip>
                    )}
                </Stack>
            ) : null}
        </CardMedia>
    );
};

export default ImagePreview;
