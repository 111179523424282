import { CustomShift, IGroupedCustomShift } from '../models/IEmployee';
import { format, eachDayOfInterval, compareAsc, addDays, isSameDay } from 'date-fns';
import ruLocale from 'date-fns/locale/ru';
import { useCallback } from 'react';

type PeriodType = {
    start: Date;
    end: Date;
};

const useCustomShiftConversions = () => {
    const groupShifts = useCallback((shifts: CustomShift[]): IGroupedCustomShift[] => {
        const result: Record<string, { data: string[]; opened: boolean; label?: string | null }> = {};
        shifts?.forEach((shift) => {
            const startHrs = format(new Date(shift.start), 'HH:mm:ss');
            const endHrs = format(new Date(shift.end), 'HH:mm:ss');
            const key = `${startHrs}_${endHrs}_${shift.opened}_${shift.label}`;

            const value = eachDayOfInterval({
                start: new Date(shift.start),
                end: new Date(shift.end)
            }).map((date) => format(new Date(date), 'yyyy-MM-dd'));

            if (result[key]) {
                result[key] = { ...result[key], data: [...result[key].data, ...value] };
            } else {
                result[key] = { data: value, opened: shift.opened, label: shift.label };
            }
        });

        return Object.entries(result).map(([k, v]) => {
            const [startTime, endTime] = k.split('_');
            return { startTime, endTime, shifts: v.data, opened: v.opened, label: v.label };
        });
    }, []);

    const getIntervals = useCallback((dates: Date[]): PeriodType[] => {
        const result: PeriodType[] = [];
        const sorted = dates.sort(compareAsc);

        sorted.forEach((date) => {
            const prevDay = addDays(date, -1);
            if (result[result.length - 1]?.end && isSameDay(result[result.length - 1].end, prevDay)) {
                result[result.length - 1].end = date;
            } else {
                result.push({ start: date, end: date });
            }
        });

        return result;
    }, []);

    const ungroupShifts = useCallback(
        (groups: IGroupedCustomShift[]): CustomShift[] => {
            let result: CustomShift[] = [];

            groups.forEach((group) => {
                const intervals = getIntervals(group.shifts.map((shift) => new Date(`${shift}T00:00:00`)));
                const converted = intervals.map((interval) => {
                    const start = `${format(interval.start, 'yyyy-MM-dd')} ${group.startTime}`;
                    const end = `${format(interval.end, 'yyyy-MM-dd')} ${group.endTime}`;
                    return { start, end, opened: group.opened, label: group.label };
                });

                result = [...result, ...converted];
            });

            return result;
        },
        [getIntervals]
    );

    const getSelectionDetails = useCallback(
        (dates: Date[]) => {
            if (dates.length) {
                const intervals = getIntervals(dates);
                const details = intervals
                    .map((interval) => {
                        if (isSameDay(interval.start, interval.end)) {
                            return format(interval.start, 'd MMM', { locale: ruLocale });
                        }
                        return `${format(interval.start, 'd MMM', { locale: ruLocale })} - ${format(interval.end, 'd MMM', {
                            locale: ruLocale
                        })}`;
                    })
                    .join(', ');
                return { label: `${intervals.length} интервалов выбрано`, details };
            }

            return { label: 'Не выбрано дат', details: null };
        },
        [getIntervals]
    );

    const getShiftExcludedValues = useCallback(
        (shiftIndex: number, shifts: IGroupedCustomShift[]) =>
            shifts.reduce<Date[]>(
                (acc, next, currentIndex) =>
                    currentIndex !== shiftIndex ? [...acc, ...next.shifts.map((s) => new Date(`${s}T00:00:00`))] : acc,
                []
            ),
        []
    );

    return { groupShifts, ungroupShifts, getSelectionDetails, getShiftExcludedValues };
};

export default useCustomShiftConversions;
