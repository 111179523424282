import { FC } from 'react';
import { IAppointment } from '../models/IAppointment';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import MoneyOffIcon from '@material-ui/icons/MoneyOff';
import { Tooltip } from '@mui/material';
import { appDateTimeFormat } from '../store/constant';
import moment from 'moment-timezone';

const AppointmentDepositOpenedIndicator: FC<{ depositRequest?: IAppointment['deposit_request']; forceMonochrome?: boolean }> = ({
    depositRequest,
    forceMonochrome = false
}) => {
    if (depositRequest && !depositRequest.paid_at) {
        return !depositRequest.opened_at ? (
            <Tooltip title="Запрос на депозит не был открыт">
                <MoneyOffIcon color={forceMonochrome ? 'inherit' : 'error'} />
            </Tooltip>
        ) : (
            <Tooltip title={`Запрос на депозит был открыт ${moment(depositRequest.opened_at).format(appDateTimeFormat)}`}>
                <AttachMoneyIcon color={forceMonochrome ? 'inherit' : 'success'} />
            </Tooltip>
        );
    }

    return null;
};

export default AppointmentDepositOpenedIndicator;
