import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { filter, startCase, throttle, toLower } from 'lodash';

import {
    CardContent,
    CircularProgress,
    Grid,
    InputAdornment,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    TextField
} from '@material-ui/core';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, useMediaQuery } from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
import SearchIcon from '@material-ui/icons/Search';
import { useAppSelector } from 'hooks/redux';
import { IEmployee, UserRole, UserRoleLabel } from 'models/IEmployee';
import employeeAPI from 'services/EmployeeService';
import { ArrangementOrder } from '../../types';
import useAuth from '../../hooks/useAuth';
import AppPagePagination from '../../ui-component/PagePagination';
import Can, { AbilityContext } from '../../utils/roles/Can';
import CreateButton from '../../ui-component/CreateButton';
import SwitchWithLabel from '../../ui-component/SwitchWithLabel';
import InviteForm from './InviteForm';
import useMobileCreateButton from '../../hooks/useMobileCreateButton';
// import usePlanName from '../../hooks/usePlanName';
import EmployeeRow from './EmployeeRow';

const EmployeeList: React.FC = () => {
    const { user } = useAuth();
    // const planName = usePlanName();

    const { deactivated } = useAppSelector((state) => state.deactivationToggle);
    const [page, setPage] = useState<number>(1);
    const [perPage, setPerPage] = useState<number>(10);
    const [search, setSearch] = useState<string | null>(null);
    const [order, setOrder] = useState<ArrangementOrder>('asc');
    const [orderBy, setOrderBy] = useState<string>('firstname');
    const [trashed, setTrashed] = useState(deactivated);

    const [emailModal, setEmailModal] = useState(false);
    const userRoles = [UserRole.Admin, UserRole.Manager, UserRole.Provider, UserRole.Owner];
    const [role, setRole] = useState<string>('All');
    const [filtered, setFiltered] = useState<IEmployee[] | undefined>(undefined);

    const isMobile = useMediaQuery('(max-width:600px)');

    const ability = useContext(AbilityContext);

    const { data, isLoading } = employeeAPI.useFetchAllEmployeesQuery({
        per_page: perPage,
        page,
        search,
        sort: orderBy,
        order,
        ...(trashed && { trashed: 'true' })
    });

    const throttled = useRef(
        throttle((newValue) => {
            setSearch(newValue);
        }, 1000)
    );

    const handleOrderBy = (property: string) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    useEffect(() => {
        if (data) {
            if (role === 'All') {
                setFiltered(data.data);
            } else {
                const searched = filter(data.data, (row) => row.role.toLowerCase() === role.toLowerCase());
                setFiltered(searched);
            }
        }
    }, [data, search, role, userRoles]);

    const tableCells = [
        { id: 'firstname', label: isMobile ? 'Имя сотрудника' : 'Имя' },
        { id: 'role', label: 'Роль' }
    ];

    const handleSearch = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => {
        const newString = event?.target.value;
        throttled.current(newString?.toLowerCase());
    };

    const handleChangeRole = (event: SelectChangeEvent) => {
        setRole(event.target.value as string);
    };

    const handleChangeStatus = useCallback(() => {
        setTrashed(!trashed);
        setPage(1);
    }, [trashed]);

    const handleClose = (limit?: number) => {
        if (limit) {
            setPerPage(limit);
            setPage(1);
        }
    };

    const handleOpenEmailModal = () => setEmailModal(true);
    const handleCloseEmailModal = () => setEmailModal(false);

    useMobileCreateButton({
        action: handleOpenEmailModal,
        condition: ability.can('create', 'employee')
    });

    return (
        <>
            <MainCard title={!isMobile ? 'Персонал' : null} content={false}>
                {!isMobile && (
                    <CardContent>
                        <Grid container justifyContent="space-between" alignItems={isMobile ? 'flex-start' : 'center'} spacing={2}>
                            <Grid item xs={10} alignItems="center" display="flex" flexWrap="wrap">
                                <Grid container justifyContent="flex-start" spacing={2} alignItems="center">
                                    <Grid item xs={isMobile ? 12 : 4}>
                                        <TextField
                                            fullWidth
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <SearchIcon fontSize="small" />
                                                    </InputAdornment>
                                                )
                                            }}
                                            onChange={handleSearch}
                                            placeholder="Поиск сотрудника"
                                            size="small"
                                        />
                                    </Grid>
                                    <Grid item xs={isMobile ? 12 : 3}>
                                        <FormControl fullWidth size="small">
                                            <InputLabel id="demo-simple-select-label">Роль</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={role}
                                                label="Role"
                                                onChange={handleChangeRole}
                                            >
                                                <MenuItem key="All" value="All">
                                                    {startCase(toLower('Все'))}
                                                </MenuItem>
                                                {userRoles.map((item) => (
                                                    <MenuItem key={item} value={item}>
                                                        {startCase(toLower(UserRoleLabel[item] ?? item))}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={isMobile ? 12 : 3}>
                                        <SwitchWithLabel
                                            width={300}
                                            labelPlacement="left"
                                            isActive={trashed}
                                            toggleStatus={handleChangeStatus}
                                            title="Показать архивных сотрудников"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Can I="create" an="employee">
                                <Grid item xs={2} sx={{ textAlign: 'right', pl: 0, flexBasis: 0 }}>
                                    <CreateButton
                                        user={user}
                                        maxCountReachedText="Пожалуйста обратитесь в поддержку чтобы повысить план и создать больше локаций, сотрудников или услуг."
                                        tooltipText="Добавить сотрудника"
                                        propertyName="employees"
                                        action={handleOpenEmailModal}
                                    />
                                </Grid>
                            </Can>
                        </Grid>
                    </CardContent>
                )}
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {tableCells.map((cell) => (
                                    <TableCell key={cell.id} align="left" sx={{ pl: 3 }} padding="none">
                                        <TableSortLabel
                                            active={orderBy === cell.id}
                                            direction={orderBy === cell.id ? order : 'asc'}
                                            onClick={() => handleOrderBy(cell.id)}
                                        >
                                            {cell.label}
                                        </TableSortLabel>
                                    </TableCell>
                                ))}
                                {!isMobile && (
                                    <>
                                        <TableCell align="left" sx={{ pl: 3 }}>
                                            Локации
                                        </TableCell>
                                        <TableCell align="left" sx={{ pl: 3 }}>
                                            Услуги
                                        </TableCell>
                                    </>
                                )}
                                {(ability.can('update', 'employee') || ability.can('delete', 'employee')) && (
                                    <TableCell align="left" sx={{ textAlign: 'center' }}>
                                        Действия
                                    </TableCell>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!isLoading && user && filtered && filtered.map((row, index) => <EmployeeRow row={row} key={row.id} />)}
                            {isLoading && (
                                <TableRow>
                                    <TableCell colSpan={6} align="center">
                                        <CircularProgress />
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                {!isLoading && data && data.data.length === 0 && (
                    <CardContent>
                        <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
                            <Grid item xs={12}>
                                Нет {trashed ? 'архивных' : 'активных'} сотрудников.
                            </Grid>
                        </Grid>
                    </CardContent>
                )}
                <AppPagePagination
                    page={page}
                    data={data}
                    perPage={perPage}
                    setPageSize={handleClose}
                    setPage={setPage}
                    additionalContent={
                        isMobile ? (
                            <SwitchWithLabel
                                width={110}
                                labelPlacement="left"
                                isActive={trashed}
                                toggleStatus={handleChangeStatus}
                                title="Архивный"
                            />
                        ) : undefined
                    }
                />
            </MainCard>
            <InviteForm open={emailModal} onClose={handleCloseEmailModal} />
        </>
    );
};

export default EmployeeList;
