import { FormControl, Grid, TextField } from '@mui/material';
import { LocalizationProvider, MobileDatePicker } from '@mui/lab';
import MomentAdapter from '@mui/lab/AdapterMoment';
import PaymentInfoSelect from '../../../../../../components/PaymentInfoDialog/components/PaymentInfoSelect';
import { methodOptions, paymentReasons, reasonOptions } from '../../../../../../components/PaymentInfoDialog/PaymentInfoSelectOptions';
import momentTimezone from 'moment-timezone';
import { useCallback, useMemo } from 'react';
import * as Yup from 'yup';
import { IPayment } from '../../../../../../../../models/IPayment';
import { useAppSelector } from '../../../../../../../../hooks/redux';
import PriceInput from '../../../../../../../../ui-component/PriceInput';
import useExtendedFormik from '../../../../../../../../hooks/useExtendedFormik';

interface PaymentFormProps {
    savePayments: (data: IPayment) => void;
    paymentIndex: number | null;
}

const PaymentForm = ({ savePayments, paymentIndex }: PaymentFormProps) => {
    const { selectedEvent } = useAppSelector((state) => state.calendar);
    const { moment } = new MomentAdapter({ instance: momentTimezone });

    const payment = useMemo(() => (paymentIndex !== null && selectedEvent?.payments ? selectedEvent.payments[paymentIndex] : null), [
        selectedEvent,
        paymentIndex
    ]);

    const formInitialValues = useMemo(
        () =>
            payment
                ? { ...payment } // clone object to make props mutable
                : {
                      datetime: moment()
                          .tz(selectedEvent?.location.time_zone || 'utc')
                          .toString(),
                      method: '',
                      reason: '',
                      amount: null,
                      code: ''
                  },
        [selectedEvent, moment, payment]
    );

    const validationSchema = Yup.object().shape({
        datetime: Yup.string().required('Введите дату'),
        reason: Yup.string().trim().required('Введите тип платежа'),
        method: Yup.mixed().when('reason', {
            is: (value: string) => value !== 'coupon',
            then: Yup.string().trim().required('Введите метод платежа'),
            otherwise: Yup.string().notRequired()
        }),
        amount: Yup.mixed().when('reason', {
            is: (value: string) => value !== 'coupon',
            then: Yup.number().required('Введите сумму платежа').min(0.01),
            otherwise: Yup.mixed().notRequired().nullable()
        }),
        code: Yup.mixed().when('reason', {
            is: 'coupon',
            then: Yup.string().trim().required('Введите код купона'),
            otherwise: Yup.string().notRequired()
        })
    });

    const onSubmit = useCallback(
        (data: IPayment) => {
            savePayments({
                ...data,
                method: data.reason === 'coupon' ? undefined : data.method,
                amount: data.reason === 'coupon' ? undefined : data.amount
            });
        },
        [savePayments]
    );

    const { handleSubmit, values, touched, errors, handleChange, handleBlur, setFieldTouched, setFieldValue } = useExtendedFormik({
        initialValues: formInitialValues,
        enableReinitialize: true,
        validateOnChange: true,
        validationSchema,
        onSubmit: (formData) => {
            formData.datetime = moment(formData.datetime).tz('UTC').toString();
            onSubmit({
                ...formData,
                amount: formData?.amount || undefined,
                method: formData.method || undefined,
                code: formData.code ? formData.code : undefined
            });
        }
    });

    const usedReasonOptions = useMemo(() => {
        if (!payment && !selectedEvent?.payments?.some((p) => p.reason === 'coupon')) {
            return reasonOptions;
        }

        return reasonOptions.filter((opt) => opt.value !== paymentReasons.coupon);
    }, [payment, selectedEvent]);

    return (
        <form noValidate onSubmit={handleSubmit} id="payment-info-form">
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <FormControl fullWidth error={Boolean(touched.datetime && errors.datetime)}>
                        <LocalizationProvider dateAdapter={MomentAdapter}>
                            <MobileDatePicker
                                label="Date"
                                inputFormat="MM/DD/YY"
                                mask="MM/DD/YY"
                                value={values.datetime}
                                onChange={(value) => {
                                    if (value) {
                                        setFieldValue('datetime', value);
                                    }
                                }}
                                renderInput={(params) => <TextField {...params} />}
                                disableCloseOnSelect={false}
                            />
                        </LocalizationProvider>
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <PaymentInfoSelect
                        touched={touched}
                        errors={errors}
                        values={values}
                        handleChange={handleChange}
                        setFieldTouched={setFieldTouched}
                        fieldName="reason"
                        label="Тип"
                        options={usedReasonOptions}
                    />
                </Grid>
                {values.reason !== 'coupon' ? (
                    // Show method and amount if type is NOT 'coupon'
                    <>
                        <Grid item xs={6}>
                            <PaymentInfoSelect
                                touched={touched}
                                errors={errors}
                                values={values}
                                handleChange={handleChange}
                                setFieldTouched={setFieldTouched}
                                fieldName="method"
                                label="Метод"
                                options={methodOptions}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                error={Boolean(touched.amount && errors.amount)}
                                name="amount"
                                value={values.amount}
                                label="Сумма"
                                inputProps={{ step: '0.01', min: '0.01' }}
                                onChange={(event) => {
                                    handleChange(event);
                                    setFieldTouched('amount', false);
                                }}
                                // eslint-disable-next-line react/jsx-no-duplicate-props
                                InputProps={{
                                    inputComponent: PriceInput as any
                                }}
                            />
                        </Grid>
                    </>
                ) : (
                    // Show coupon code if payment type is 'coupon'
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            name="code"
                            value={values.code}
                            label="Код купона"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={Boolean(touched.code && errors.code)}
                            helperText={touched.code ? errors.code : undefined}
                        />
                    </Grid>
                )}
            </Grid>
        </form>
    );
};

export default PaymentForm;
