import { FC, useMemo, useState } from 'react';
import { IThreadAttachment } from '../../models/IAppointment';
import { Box, ButtonBase, Link } from '@mui/material';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import CBModal from '../cb-modal/CBModal';

const ChatAttachment: FC<{ attachment: IThreadAttachment }> = ({ attachment }) => {
    const [open, setOpen] = useState(false);
    const isImage = useMemo(() => attachment.mime_type.startsWith('image/'), [attachment.mime_type]);

    return (
        <Box>
            {isImage ? (
                <>
                    <ButtonBase
                        onClick={() => setOpen(true)}
                        sx={{
                            background: '#fff',
                            p: 1,
                            borderRadius: '8px',
                            cursor: 'pointer',
                            position: 'relative',
                            '& > img': {
                                width: '250px',
                                height: '250px',
                                objectPosition: 'center',
                                objectFit: 'cover',
                                borderRadius: '4px'
                            }
                        }}
                    >
                        <img src={attachment.file_url} alt="" />
                    </ButtonBase>
                    <CBModal open={open} onClose={() => setOpen(false)} maxWidth="lg" fullWidth>
                        <Box
                            sx={{
                                width: '100%',
                                '& > img': {
                                    width: '100%',
                                    height: 'auto',
                                    maxHeight: '80vh',
                                    objectFit: 'contain',
                                    objectPosition: 'center'
                                }
                            }}
                        >
                            <img src={attachment.file_url} alt="" />
                        </Box>
                    </CBModal>
                </>
            ) : (
                <Link
                    href={attachment.file_url}
                    download
                    target="_blank"
                    sx={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        wordBreak: 'break-all',
                        '& .MuiSvgIcon-root': {
                            fontSize: '1.2em'
                        }
                    }}
                >
                    <AttachFileIcon /> {attachment.file_name}
                </Link>
            )}
        </Box>
    );
};

export default ChatAttachment;
