import { FC, useEffect, useMemo, useRef } from 'react';
import { Alert } from '@mui/material';

const ExistingCustomerAlert: FC<{ email?: string | null; phone?: string | null }> = ({ email, phone }) => {
    const alertRef = useRef<any>(null);

    const text = useMemo(() => {
        const emailError = email ? `email ${email}` : null;
        const phoneError = phone ? `телефоном ${phone}` : null;
        const errors = [emailError, phoneError].filter((err) => !!err);

        if (emailError || phoneError) {
            return `Клиент с ${[errors.join(' и ')]} уже существует.`;
        }

        return null;
    }, [email, phone]);

    useEffect(() => {
        if (text) {
            alertRef?.current?.scrollIntoView({ block: 'start' });
        }
    }, [text]);

    return text ? (
        <Alert severity="info" sx={{ mb: 1 }} ref={alertRef}>
            {text}
        </Alert>
    ) : null;
};

export default ExistingCustomerAlert;
