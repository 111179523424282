import { RefObject, useCallback, useEffect, useMemo, useState } from 'react';
import { ICustomer, ICustomerPayload } from '../../../../models/ICustomer';
import { useDebouncedCallback } from 'use-debounce';
import { axiosServices } from '../../../../utils/axios';
import { IPaginateResponse } from '../../../../models/IPaginateResponse';
import { Autocomplete, TextField, Theme, useMediaQuery } from '@material-ui/core';
import { FormFieldProps } from '../../index';
import { Box, Divider, Grid, Popper, Portal } from '@mui/material';
import AddIconButton from '../../../AddIconButton';
import SectionHeading from '../../../../views/calendar/appointment-card/appointment-details/elements/SectionHeading';
import CBModal from '../../../cb-modal/CBModal';
import UnfilledButton from '../../../form/buttons/UnfilledButton';
import FilledButton from '../../../form/buttons/FilledButton';
import { displayPhone } from '../../../../utils/phone-helpers';
import CustomerForm from '../../../CustomerForm';
import useCustomerFormDefaults from '../../../../views/customer/hooks/use-customer-form-defaults';

interface CustomerSelectProps extends FormFieldProps {
    formContainerRef: RefObject<HTMLDivElement>;
    isNewCustomerOpen: boolean;
    closeNewCustomer: () => void;
    openNewCustomer: () => void;
    disabled?: boolean;
    onSaveCustomer: (formData: ICustomerPayload, id?: number) => void;
    submitBtnRef?: RefObject<HTMLButtonElement>;
    customerSelectCb?: (customer: ICustomer) => void;
    existingCustomer?: ICustomer | null;
}

const CustomerSelect = ({
    values,
    setFieldValue,
    touched,
    errors,
    setFieldTouched,
    handleBlur,
    formContainerRef,
    isNewCustomerOpen,
    closeNewCustomer,
    openNewCustomer,
    disabled,
    onSaveCustomer,
    submitBtnRef,
    customerSelectCb,
    existingCustomer
}: CustomerSelectProps) => {
    const [loading, setLoading] = useState(false);
    const [searchString, setSearchString] = useState<string>('');
    const [options, setOptions] = useState<ICustomer[]>([]);
    const matchSm = useMediaQuery((themeParam: Theme) => themeParam.breakpoints.down('sm'));

    const customer = useMemo(() => values?.customer, [values]);
    const defaults = useCustomerFormDefaults(existingCustomer);

    const getOptions = useDebouncedCallback((searchTerm, callback) => {
        setLoading(true);
        axiosServices
            .get(`/customers/`, {
                params: {
                    search: searchTerm,
                    per_page: 10
                }
            })
            .then((data) => {
                callback(data);
            })
            .finally(() => {
                setLoading(false);
            });
    }, 200);

    const getOptionLabel = useCallback((option: ICustomer) => {
        if (typeof option === 'string') {
            return option;
        }
        console.log(option.is_pd_masked);
        const phone = option.is_pd_masked ? option.phone : displayPhone(option.phone ?? '');

        let returnString = `${option.firstname} ${option.lastname ? option.lastname : ' '}`;

        if (phone) {
            returnString += `(${phone})`;
        } else if (option.email) {
            returnString += `(${option.email})`;
        }

        return returnString;
    }, []);

    const existingCustomerId = existingCustomer?.id;

    const saveCustomer = useCallback(
        (formData: ICustomerPayload) => {
            onSaveCustomer(formData, existingCustomerId);
        },
        [existingCustomerId, onSaveCustomer]
    );

    const handleOpenNewCustomer = useCallback(() => {
        openNewCustomer();
        setFieldValue('customer', null);
    }, [openNewCustomer, setFieldValue]);

    const isPopperForcedToClose = useMemo(() => {
        const noSearchText = !searchString;
        const equalToOnlyOption = options.length === 1 && getOptionLabel(options[0]) === searchString;

        return noSearchText || equalToOnlyOption;
    }, [getOptionLabel, options, searchString]);

    const handleCustomerSelect = useCallback(
        (e: unknown, newVal: ICustomer | null) => {
            setFieldTouched('customer');
            setFieldValue('customer', newVal);
            if (newVal && customerSelectCb) {
                customerSelectCb(newVal);
            }
        },
        [customerSelectCb, setFieldTouched, setFieldValue]
    );

    useEffect(() => {
        if (!searchString) return setOptions(customer ? [customer] : []);

        return getOptions(searchString, ({ data }: { data: IPaginateResponse<ICustomer[]> }) => {
            setOptions(data.data);
        });
    }, [customer, getOptions, searchString]);

    return (
        <>
            {!isNewCustomerOpen && (
                <>
                    <Grid item xs={12}>
                        <SectionHeading mb={1}>Клиент</SectionHeading>
                    </Grid>
                    <Box display="flex" gap={1}>
                        <Autocomplete
                            filterOptions={(opts) => opts}
                            openOnFocus={false}
                            id="customer-select"
                            fullWidth
                            getOptionLabel={getOptionLabel}
                            options={options}
                            isOptionEqualToValue={(option, value) => value && option.id === value.id}
                            includeInputInList
                            value={customer || null}
                            onChange={handleCustomerSelect}
                            onBlur={(e) => {
                                handleBlur(e);
                                setFieldTouched('customer');
                            }}
                            inputValue={searchString}
                            onInputChange={(event, newInputValue) => setSearchString(newInputValue)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Поиск по имени, телефону или email."
                                    error={Boolean(touched?.customer && errors?.customer)}
                                    helperText={touched?.customer ? errors?.customer : null}
                                />
                            )}
                            disabled={disabled}
                            noOptionsText={loading ? 'Загрузка...' : 'Нет результатов'}
                            PopperComponent={(props) => <Popper {...props} open={props.open && !isPopperForcedToClose} />}
                        />
                        <AddIconButton onClick={handleOpenNewCustomer} disabled={disabled} />
                    </Box>
                </>
            )}

            {isNewCustomerOpen && !matchSm && (
                <Portal container={formContainerRef.current}>
                    <Box>
                        <Grid item xs={12}>
                            <SectionHeading mb={1}>Создать клиента</SectionHeading>
                        </Grid>
                        <CustomerForm
                            customer={defaults}
                            onSubmit={saveCustomer}
                            formId="new-customer-form"
                            hideTitles
                            skipChangesCheck
                            isNew
                        />
                        <Box py={2} display="flex" gap={2} justifyContent="flex-end">
                            <UnfilledButton onClick={closeNewCustomer} text="Отмена" />
                            <FilledButton
                                type="submit"
                                form="new-customer-form"
                                text={existingCustomer ? 'Обновить и добавить' : 'Добавить'}
                                buttonRef={submitBtnRef}
                            />
                        </Box>
                        <Divider sx={{ mx: -2 }} />
                    </Box>
                </Portal>
            )}
            {matchSm && (
                <CBModal
                    fullScreen
                    title="Создать запись"
                    open={isNewCustomerOpen}
                    onClose={closeNewCustomer}
                    okButtonText={existingCustomer ? 'Обновить и добавить' : 'Добавить'}
                    okButtonFormId="new-customer-form"
                    submitButtonRef={submitBtnRef}
                >
                    <Box>
                        <SectionHeading mb={1}>{isNewCustomerOpen ? 'Создать клиента' : 'Клиент'}</SectionHeading>
                        <CustomerForm
                            customer={defaults}
                            onSubmit={saveCustomer}
                            formId="new-customer-form"
                            hideTitles
                            skipChangesCheck
                            isNew
                        />
                    </Box>
                </CBModal>
            )}
        </>
    );
};

export default CustomerSelect;
