import menuItems, { NavItemWithChildren } from './index';
import { useCallback, useContext, useMemo } from 'react';
import { UserSubject } from '../models/IUser';
import { AbilityContext } from '../utils/roles/Can';
import useAuth from '../hooks/useAuth';

const useAvailableMenuItems = () => {
    const { user } = useAuth();
    const ability = useContext(AbilityContext);
    const productsEnabled = !!user?.currentCompany.settings?.appointments.use_products;

    const canSeeMenuItem = useCallback((role_subject?: string) => ability.can('list', role_subject as UserSubject), [ability]);

    const filteredItems = useMemo(
        () =>
            menuItems.items.reduce<NavItemWithChildren[]>((prev, current) => {
                const filteredChildren = current.children.filter((child) => {
                    if (child.id === 'products') {
                        return canSeeMenuItem(child.role_subject) && productsEnabled;
                    }
                    return canSeeMenuItem(child.role_subject);
                });
                return filteredChildren.length ? [...prev, { ...current, children: filteredChildren }] : prev;
            }, []),
        [canSeeMenuItem, productsEnabled]
    );

    return { filteredItems };
};

export default useAvailableMenuItems;
