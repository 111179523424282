import { Link } from 'react-router-dom';
import { Fab, Tooltip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

interface AddIconButtonProps {
    tooltip: string;
    to: string;
}

const AddIconFab = ({ tooltip, to }: AddIconButtonProps) => (
    <Tooltip title={tooltip}>
        <Link to={to}>
            <Fab color="secondary" size="small" sx={{ boxShadow: 'none', ml: 1, width: '32px', height: '32px', minHeight: '32px' }}>
                <AddIcon fontSize="small" />
            </Fab>
        </Link>
    </Tooltip>
);

export default AddIconFab;
