import * as Yup from 'yup';
import { IService, PaymentType } from '../../../models/IService';

const servicesSchema = Yup.object().shape({
    service: Yup.object()
        .shape({ id: Yup.number() })
        .test('service', 'Выберите услугу', (v) => !!v.id)
        .required('Выберите услугу')
        .typeError('Выберите услугу'),
    price: Yup.number()
        .min(0)
        .nullable()
        .required('Укажите стоимость')
        .typeError('Стоимость должна быть числом')
        // eslint-disable-next-line func-names
        .test('price', '', function (v) {
            const { path, parent, createError } = this;
            const isPrepaid = parent.service?.payment_type === PaymentType.Prepaid;

            if (isPrepaid) {
                const price = v ? parseFloat(String(v)) : 0;
                const deposit = parent.prepay ? parseFloat(String(parent.prepay)) : 0;
                const isValid = deposit <= price;

                return (
                    isValid ||
                    createError({
                        path,
                        message: `Стоимость не может быть меньше депозита (${parent.prepay} руб.)`
                    })
                );
            }

            return true;
        }),

    prepay: Yup.mixed().when('service', {
        is: (service: IService | undefined) => service && service.payment_type === PaymentType.Prepaid,
        then: Yup.number().min(0).typeError('Депозит должен быть числом').required('укажите депозит'),
        otherwise: Yup.mixed().nullable().notRequired()
    }),

    materials_amount: Yup.mixed().when('service', {
        is: (service: IService | undefined) => service && !!service.use_materials,
        then: Yup.number()
            .min(0)
            .typeError('Количество материалов должно быть числом')
            .typeError('Укажите количество материала')
            .label('Количество материала'),
        otherwise: Yup.mixed().nullable().notRequired()
    })
});

export default servicesSchema;
