export const methodOptions = [
    { value: 'cash', label: 'Наличные' },
    { value: 'credit_card', label: 'Карта' },
    { value: 'gift_card', label: 'Подарочная карта' }
];

export const paymentReasons = {
    gratuity: 'gratuity',
    deposit: 'deposit',
    service: 'service',
    other: 'other',
    coupon: 'coupon'
};

export const reasonOptions = [
    { value: paymentReasons.deposit, label: 'Депозит' },
    { value: paymentReasons.service, label: 'Оплата' },
    { value: paymentReasons.gratuity, label: 'Чаевые' },
    { value: paymentReasons.coupon, label: 'Купон' },
    { value: paymentReasons.other, label: 'Другое' }
];
