import { FC, useCallback, useEffect, useState } from 'react';
import CBModal from './cb-modal/CBModal';
import { Button } from '@mui/material';
import serviceAPI from '../services/ServiceService';
import useShowSnackbar from '../hooks/useShowSnackbar';
import { SnackBarTypes } from '../store/snackbarReducer';
import * as Yup from 'yup';
import TextField from '@mui/material/TextField';
import { IService } from '../models/IService';
import useExtendedFormik from '../hooks/useExtendedFormik';

const SimpleAddServiceDialog: FC<{ onSuccess?: (service: IService) => void }> = ({ onSuccess }) => {
    const [open, setOpen] = useState(false);
    const [createService, { isLoading }] = serviceAPI.useSimpleCreateServiceMutation();
    const { showSnackbar } = useShowSnackbar();

    const handleClose = useCallback(() => {
        setOpen(false);
    }, []);

    const addService = useCallback(
        (serviceName: string) => {
            if (isLoading) return;

            // capitalize entered service name
            createService(serviceName.replace(/\b\w/g, (l) => l.toUpperCase()))
                .unwrap()
                .then((service) => {
                    if (service.id) {
                        showSnackbar({
                            message: 'Услуга успешно создана',
                            alertSeverity: SnackBarTypes.Success
                        });
                        onSuccess?.(service);
                        handleClose();
                    }
                })
                .catch((err) => {
                    showSnackbar({
                        message: err.data || 'Произошла ошибка, услуга не создана',
                        alertSeverity: SnackBarTypes.Error
                    });
                });
        },
        [createService, handleClose, isLoading, onSuccess, showSnackbar]
    );

    const { values, errors, touched, handleBlur, handleChange, resetForm, handleSubmit } = useExtendedFormik<{ name: string }>({
        initialValues: { name: '' },
        onSubmit: ({ name }) => addService(name),
        validationSchema: Yup.object().shape({
            name: Yup.string().trim().required('Введите название услуги.')
        })
    });

    useEffect(() => {
        if (open) {
            resetForm();
        }
    }, [open, resetForm]);

    return (
        <>
            <Button onClick={() => setOpen(true)}>+Добавить услугу</Button>
            <CBModal
                open={open}
                onClose={handleClose}
                title="Добавить услугу"
                okButtonFormId="simpleFormDialog"
                okButtonText="Сохранить"
                closeButtonText="Отмена"
                okButtonDisabled={isLoading}
            >
                <form
                    id="simpleFormDialog"
                    onSubmit={(e) => {
                        e.stopPropagation();
                        handleSubmit(e);
                    }}
                >
                    <TextField
                        fullWidth
                        label="Название услуги"
                        id="name"
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!errors.name && !!touched.name}
                        helperText={errors.name && touched.name ? errors.name : undefined}
                    />
                </form>
            </CBModal>
        </>
    );
};

export default SimpleAddServiceDialog;
