import { createApi } from '@reduxjs/toolkit/dist/query/react';
import {
    AppointmentStatusPayload,
    ICalendarAppointment,
    IGlobalAppointment,
    IAppointment,
    IAppointmentConversation,
    IAppointmentHistory,
    IAppointmentInvitePayload,
    IAppointmentPayload,
    IConversationDetails,
    IVoiceAppointmentPayload
} from '../models/IAppointment';
import { axiosBaseQuery } from '../utils/axios';
import { TimeSlot } from '../views/scheduling-widget/widget-wizard/types';
import { IService } from '../models/IService';
import { ILocation } from '../models/ILocation';
import { IEmployee } from '../models/IEmployee';
import { IPaginateResponse } from '../models/IPaginateResponse';
import { IPayment } from '../models/IPayment';
import { IAppointmentProduct } from '../models/IProduct';

export interface AppointmentsFetchProps {
    mode: string;
    date: string;
    employee?: string;
    location?: string;
    customer?: string;
    customerInfo?: string | null;
}

export interface AppointmentPaginatedFetchProps {
    customerInfo?: string;
    employee?: string;
    location?: string;
    customer?: string;
    page?: number;
    per_page?: number;
}

export interface TimeSlotsParams {
    date: string;
    service: IService['id'];
    location: ILocation['id'];
    employee?: IEmployee['id'];
}

export interface IAppointmentCommentPayload {
    appointmentId: number;
    conversationId: number;
    text: string;
    attachment?: File | null;
}

const appointmentAPI = createApi({
    reducerPath: 'appointmentAPI',
    tagTypes: ['Appointment', 'EventEmployees', 'AppointmentProducts'],
    baseQuery: axiosBaseQuery(),
    endpoints: (build) => ({
        fetchAllAppointments: build.query<ICalendarAppointment[], AppointmentsFetchProps>({
            query: ({ mode, date, employee, location, customer, customerInfo }) => ({
                url: `/appointments`,
                method: 'GET',
                params: {
                    mode,
                    date,
                    'filters[employee]': employee,
                    'filters[location]': location,
                    'filters[customer]': customer,
                    'filters[customerInfo]': customerInfo
                }
            }),
            providesTags: (result) => ['Appointment'],
            keepUnusedDataFor: 30
        }),
        fetchAllPaginatedAppointments: build.query<IPaginateResponse<ICalendarAppointment[]>, AppointmentPaginatedFetchProps>({
            query: ({ employee, location, customer, customerInfo, page = 1, per_page = 5 }) => ({
                url: `/appointments`,
                method: 'GET',
                params: {
                    'filters[employee]': employee,
                    'filters[location]': location,
                    'filters[customer]': customer,
                    'filters[customerInfo]': customerInfo,
                    page,
                    per_page,
                    sort: 'start_at',
                    order: 'desc'
                }
            }),
            providesTags: (result) => ['Appointment'],
            keepUnusedDataFor: 30
        }),

        fetchGlobalAppointments: build.query<IGlobalAppointment[], AppointmentsFetchProps>({
            query: ({ mode, date, employee, location, customer, customerInfo }) => ({
                url: `/global-appointments`,
                method: 'GET',
                params: {
                    mode,
                    date,
                    'filters[employee]': employee,
                    'filters[location]': location,
                    'filters[customer]': customer,
                    'filters[customerInfo]': customerInfo
                }
            }),
            providesTags: (result) => ['Appointment'],
            keepUnusedDataFor: 30
        }),
        getAppointment: build.query<IAppointment, number>({
            query: (id) => ({
                url: `/appointments/${id}`,
                method: 'GET'
            }),
            providesTags: (result) => ['Appointment']
        }),
        createAppointment: build.mutation<IAppointment, IAppointmentPayload | IVoiceAppointmentPayload>({
            query: (data) => ({
                url: `/appointments/`,
                method: 'POST',
                data
            }),
            invalidatesTags: ['Appointment']
        }),
        updateAppointment: build.mutation<IAppointment, { appointmentId: string; data: IAppointmentPayload }>({
            query: ({ appointmentId, data }) => ({
                url: `/appointments/${appointmentId}`,
                method: 'PUT',
                data
            }),
            invalidatesTags: ['Appointment']
        }),

        createAppointmentPayment: build.mutation<unknown, { appointmentId: string; payments: IPayment[] }>({
            query: ({ appointmentId, payments }) => ({
                url: `/appointments/${appointmentId}/payments`,
                method: 'POST',
                data: { payments }
            }),
            invalidatesTags: ['Appointment']
        }),

        updateAppointmentPayment: build.mutation<
            IAppointment,
            {
                appointmentId: string;
                data: IPayment;
                paymentIndex: number;
            }
        >({
            query: ({ appointmentId, data, paymentIndex }) => ({
                url: `/appointments/${appointmentId}/payments/${paymentIndex}`,
                method: 'PUT',
                data
            }),
            invalidatesTags: ['Appointment']
        }),

        deleteAppointmentPayment: build.mutation<unknown, { appointmentId: string; paymentIndex: number }>({
            query: ({ appointmentId, paymentIndex }) => ({
                url: `/appointments/${appointmentId}/payments/${paymentIndex}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['Appointment']
        }),

        deleteAppointment: build.mutation<IAppointment, string | number>({
            query: (appointmentId) => ({
                url: `/appointments/${appointmentId}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['Appointment']
        }),
        setAppointmentStatus: build.mutation<
            IAppointment,
            {
                appointmentId: IAppointment['id'];
                data: AppointmentStatusPayload;
            }
        >({
            query: ({ appointmentId, data: { status, payment_method, price, cancel_reason } }) => ({
                url: `/appointments/${appointmentId}/status`,
                method: 'PUT',
                data: {
                    status,
                    cancel_reason,
                    payment_method,
                    price
                }
            }),
            invalidatesTags: ['Appointment']
        }),
        getTimeSlots: build.query<TimeSlot[], TimeSlotsParams>({
            query: ({ date, service, location, employee }) => ({
                url: `/appointments/slots/`,
                method: 'GET',
                params: {
                    date,
                    service,
                    location,
                    employee
                }
            }),
            providesTags: (result) => ['Appointment']
        }),
        getAppointmentHistory: build.query<IAppointmentHistory[], number>({
            query: (id) => ({
                url: `/appointments/${id}/audits`,
                method: 'GET'
            }),
            providesTags: (result) => ['Appointment']
        }),
        sendInviteToSchedule: build.mutation<unknown, IAppointmentInvitePayload>({
            query: (data) => ({
                url: `/appointment-invites`,
                method: 'POST',
                data
            })
        }),

        sendSelfCheckoutInvite: build.mutation<unknown, { phone: string; appointment_id: number }>({
            query: ({ phone, appointment_id }) => ({
                url: `/appointments/${appointment_id}/self-checkout-invite`,
                method: 'POST',
                data: { phone }
            })
        }),

        resendWaiverConfirmation: build.mutation<unknown, number>({
            query: (id) => ({
                url: `appointments/${id}/waiver-reminder`,
                method: 'POST'
            }),
            invalidatesTags: ['Appointment']
        }),

        getConversationsList: build.query<Array<IAppointmentConversation>, number>({
            query: (appointmentId) => ({
                url: `appointments/${appointmentId}/messaging/conversations`,
                method: 'GET'
            }),
            keepUnusedDataFor: 5
        }),

        getSingleConversation: build.query<IConversationDetails, { appointmentId: number; conversationId: number }>({
            query: ({ appointmentId, conversationId }) => ({
                url: `appointments/${appointmentId}/messaging/conversations/${conversationId}`,
                method: 'GET'
            }),
            keepUnusedDataFor: 5
        }),

        sendComment: build.mutation<IConversationDetails, IAppointmentCommentPayload>({
            query: ({ appointmentId, conversationId, text, attachment }) => {
                const data = new FormData();
                data.append('text', text);
                if (attachment) {
                    data.append('attachment', attachment);
                }
                return {
                    url: `appointments/${appointmentId}/messaging/conversations/${conversationId}/send-message`,
                    method: 'POST',
                    headers: {
                        'Content-Type': 'multipart/form-data;'
                    },
                    data
                };
            }
        }),

        createNewConversation: build.mutation<IConversationDetails, Omit<IAppointmentCommentPayload, 'conversationId'>>({
            query: ({ appointmentId, text, attachment }) => {
                const data = new FormData();
                data.append('text', text);
                if (attachment) {
                    data.append('attachment', attachment);
                }

                return {
                    url: `appointments/${appointmentId}/messaging/conversations/create`,
                    method: 'POST',
                    headers: {
                        'Content-Type': 'multipart/form-data;'
                    },
                    data
                };
            }
        }),

        listAppointmentProducts: build.query<IAppointmentProduct[], { appointmentId: number }>({
            query: ({ appointmentId }) => ({
                url: `appointments/${appointmentId}/products`,
                method: 'GET'
            }),
            providesTags: ['AppointmentProducts']
        }),

        showAppointmentProduct: build.query<IAppointmentProduct, { appointmentId: number; productId: number }>({
            query: ({ appointmentId, productId }) => ({
                url: `appointments/${appointmentId}/products/${productId}`,
                method: 'GET'
            }),
            providesTags: ['AppointmentProducts']
        }),

        createAppointmentProduct: build.mutation<
            IAppointmentProduct,
            {
                appointmentId: number;
                productId: number;
                payload: { price: number; amount: number };
            }
        >({
            query: ({ appointmentId, productId, payload }) => ({
                url: `appointments/${appointmentId}/products/${productId}`,
                method: 'POST',
                data: payload
            }),
            invalidatesTags: ['Appointment']
        }),

        updateAppointmentProduct: build.mutation<
            IAppointmentProduct,
            {
                appointmentId: number;
                productId: number;
                payload: { price: number; amount: number };
            }
        >({
            query: ({ appointmentId, productId, payload }) => ({
                url: `appointments/${appointmentId}/products/${productId}`,
                method: 'PUT',
                data: payload
            }),
            invalidatesTags: ['Appointment']
        }),

        deleteAppointmentProduct: build.mutation<
            unknown,
            {
                appointmentId: number;
                productId: number;
            }
        >({
            query: ({ appointmentId, productId }) => ({
                url: `appointments/${appointmentId}/products/${productId}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['Appointment']
        })
    })
});

export default appointmentAPI;
