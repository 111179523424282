import customerAPI from '../../../services/CustomerService';
import { Grid, Skeleton } from '@material-ui/core';
import CBModal from '../../../ui-component/cb-modal/CBModal';
import CustomerAppointments from '../../../ui-component/customer-appointments';
import CustomerForm from '../../../ui-component/CustomerForm';
import SectionHeading from '../../calendar/appointment-card/appointment-details/elements/SectionHeading';
import { Box, ModalProps, useMediaQuery } from '@mui/material';
import appointmentAPI from '../../../services/AppointmentService';
import { useAppDispatch } from '../../../hooks/redux';
import useShowSnackbar from '../../../hooks/useShowSnackbar';
import { SnackBarTypes } from '../../../store/snackbarReducer';
import { useCallback, useContext, useMemo } from 'react';
import { AbilityContext } from '../../../utils/roles/Can';
import { useTheme } from '@mui/material/styles';
import { startSubmitting, stopSubmitting } from '../../../store/slices/SubmittingSlice';
import { DialogTypes, openDialog } from '../../../store/slices/entityDialogsSlice';
import getErrorMessage from '../../../utils/get-error-message';
import useCustomerFormDefaults from '../hooks/use-customer-form-defaults';
import { ICustomerPayload } from '../../../models/ICustomer';
import useCanSeeCustomerDetails from '../../../hooks/use-can-see-customer-details';

interface CustomerInfoModalProps {
    customerId: string;
    onClose: () => void;
    container?: ModalProps['container'];
}

const CustomerInfoDialog = ({ customerId, onClose, container }: CustomerInfoModalProps) => {
    const theme = useTheme();
    const matchSm = useMediaQuery(theme.breakpoints.down('sm'));
    const dispatch = useAppDispatch();
    const { data, isFetching } = customerAPI.useGetCustomerQuery(customerId, {
        refetchOnMountOrArgChange: true
    });

    const defaults = useCustomerFormDefaults(data);

    const { showSnackbar } = useShowSnackbar();
    const ability = useContext(AbilityContext);

    const [updateCustomer, { isLoading: saving }] = customerAPI.useUpdateCustomerMutation();

    const { canEditCustomerDetails } = useCanSeeCustomerDetails();

    const canEdit = useMemo(() => ability.can('update', 'customer') && canEditCustomerDetails(data?.employee_owner?.id), [
        ability,
        canEditCustomerDetails,
        data
    ]);

    const handleUpdate = useCallback(
        (customer: ICustomerPayload) => {
            if (data && canEdit && !saving) {
                dispatch(startSubmitting());
                updateCustomer({ id: data.id, ...customer })
                    .unwrap()
                    .then(() => {
                        showSnackbar({ alertSeverity: SnackBarTypes.Success, message: 'Клиент обновлен' });
                        dispatch(appointmentAPI.util.invalidateTags(['Appointment']));
                        onClose();
                    })
                    .catch((err) => {
                        const message = getErrorMessage(err);
                        showSnackbar({ alertSeverity: SnackBarTypes.Error, message });
                    })
                    .finally(() => {
                        dispatch(stopSubmitting());
                    });
            }
        },
        [data, canEdit, saving, dispatch, updateCustomer, showSnackbar, onClose]
    );

    const onViewAppointment = useCallback(
        (id: number) => {
            dispatch(openDialog({ type: DialogTypes.Appointment, id }));
        },
        [dispatch]
    );

    return (
        <CBModal
            open
            onClose={onClose}
            fullWidth
            maxWidth="sm"
            title="Данные клиента"
            okButtonText={canEdit ? 'Сохранить' : undefined}
            okButtonFormId={canEdit ? 'CustomerDetailsForm' : undefined}
            okButtonDisabled={isFetching || saving || !canEdit}
            fullScreen={matchSm}
            container={container}
        >
            {data && !isFetching ? (
                <Box>
                    <CustomerForm
                        customer={defaults}
                        onSubmit={handleUpdate}
                        formId="CustomerDetailsForm"
                        isReadOnly={saving || !canEdit}
                        skipChangesCheck
                    />
                    <SectionHeading mt={2} mb={1}>
                        Записи клиента
                    </SectionHeading>
                    <CustomerAppointments customerId={parseInt(customerId, 10)} onViewAppointment={onViewAppointment} />
                </Box>
            ) : (
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <Skeleton animation="wave" height={30} />
                        <Skeleton animation="wave" height={30} />
                        <Skeleton animation="wave" height={30} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Skeleton animation="wave" height={30} />
                        <Skeleton animation="wave" height={30} />
                        <Skeleton animation="wave" height={30} />
                    </Grid>
                    <Grid item xs={12}>
                        <Skeleton animation="wave" height={50} />
                        <Skeleton animation="wave" height={50} />
                    </Grid>
                </Grid>
            )}
        </CBModal>
    );
};

export default CustomerInfoDialog;
