import React, { createContext, useCallback, useEffect, useMemo } from 'react';
import { ACCOUNT_INITIALIZE, LOGIN, LOGOUT, SET_SUB_RENEW_DATA } from 'store/account/actions';
import { axiosServices } from 'utils/axios';
import { initialLoginContextProps } from 'types';
import { IUser } from '../models/IUser';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { useLocation } from 'react-router-dom';
import config from '../config';
import Loader from '../ui-component/Loader';
import { useNavigate } from 'react-router';

export interface RegisterPayload {
    email: string;
    firstname: string;
    lastname: string;
    password?: string;
    phone?: string;
    address: {
        address: string;
        city: string;
        state: string;
        postal_code: string;
        l1?: string;
        l2?: string;
        country: string | null;
    };
    company: {
        lead_uuid: string;
        industry: string;
        name: string;
        subscription_type: string;
        time_zone: string;
    };
    stripe_price_id: string;
    with_trial: boolean;
}

const initialState: initialLoginContextProps = {
    isLoggedIn: false,
    isInitialized: false,
    user: null
};

const SanctumContext = createContext({
    ...initialState,
    login: async (e: string, p: string) => {},
    register: async (values: RegisterPayload) => {},
    logout: () => {},
    changeCompanyContext: async (companyId: number) => {},
    checkAuthentication: async () => {}
});

export const SanctumProvider = ({ children }: { children: React.ReactElement }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const { user, isLoggedIn, isInitialized } = useSelector((AppState: RootState) => AppState.account);

    const csrf = () => axiosServices.get('csrf-cookie');

    const signOut = async () => {
        try {
            await axiosServices.post('/logout');
            return true;
        } catch (error) {
            return error;
        }
    };

    const register = useCallback(
        (values: RegisterPayload): Promise<any> =>
            new Promise((resolve, reject) => {
                csrf()
                    .then(() => {
                        axiosServices
                            .post('/validate-register', values)
                            .then(({ data }) => {
                                document.cookie = `cbLeadId=; max-age=${60 * 60 * 24}`;
                                if (data?.url) window.location.href = data.url;
                                resolve(data);
                            })
                            .catch((error) => {
                                reject(error);
                            });
                    })
                    .catch((error) => {
                        reject(error);
                    });
            }),
        []
    );

    const login = useCallback(
        (email: string, password: string): Promise<any> =>
            new Promise((resolve, reject) => {
                csrf()
                    .then(() => {
                        axiosServices
                            .post(
                                '/login',
                                {
                                    email,
                                    password,
                                    remember: null
                                },
                                {
                                    maxRedirects: 0
                                }
                            )
                            .then((res) => {
                                const { required_subscription, redirect_url } = res.data;
                                if (required_subscription && redirect_url) {
                                    dispatch({
                                        type: SET_SUB_RENEW_DATA,
                                        payload: { required_subscription, redirect_url }
                                    });
                                    navigate('/login/required-subscription');
                                } else {
                                    axiosServices
                                        .get<IUser>('/account', {
                                            maxRedirects: 0
                                        })
                                        .then(({ data }) => {
                                            dispatch({
                                                type: LOGIN,
                                                payload: {
                                                    isLoggedIn,
                                                    isInitialized,
                                                    user: data
                                                }
                                            });

                                            if (data.select_company_required && data.companies.length === 0) {
                                                navigate('/select-organization');
                                            } else {
                                                resolve(data);
                                            }
                                        });
                                }
                            })
                            .catch((error) => {
                                reject(error);
                            });
                    })
                    .catch((error) => {
                        reject(error);
                    });
            }),
        [dispatch, navigate, isInitialized, isLoggedIn]
    );

    const logout = useCallback(() => {
        signOut().then(() => {
            dispatch({ type: LOGOUT });
        });
    }, [dispatch]);

    const changeCompanyContext = useCallback(
        (companyId: number): Promise<any> =>
            new Promise((resolve, reject) => {
                axiosServices
                    .post(
                        '/account/change-company',
                        {
                            company_id: companyId
                        },
                        {
                            maxRedirects: 0
                        }
                    )
                    .then(() => {
                        axiosServices
                            .get<IUser>('/account', {
                                maxRedirects: 0
                            })
                            .then(({ data }) => {
                                dispatch({
                                    type: LOGIN,
                                    payload: {
                                        isLoggedIn,
                                        isInitialized,
                                        user: data
                                    }
                                });
                                resolve(data);
                            });
                    })
                    .catch((error) => {
                        reject(error);
                    });
            }),
        [dispatch, isLoggedIn, isInitialized]
    );

    const checkAuthentication = useCallback(async (): Promise<void> => {
        try {
            const { data } = await axiosServices.get<IUser>('/account', {
                maxRedirects: 0
            });

            dispatch({
                type: ACCOUNT_INITIALIZE,
                payload: {
                    isInitialized,
                    isLoggedIn: true,
                    user: data
                }
            });

            if (data.select_company_required && data.companies.length === 0) navigate('/select-organization');
        } catch (error) {
            dispatch({
                type: ACCOUNT_INITIALIZE,
                payload: {
                    isInitialized,
                    isLoggedIn: false,
                    user: null
                }
            });
        }
    }, [dispatch, navigate, isInitialized]);

    const value = useMemo(
        () => ({
            login,
            logout,
            register,
            changeCompanyContext,
            checkAuthentication,
            user,
            isLoggedIn,
            isInitialized
        }),
        [changeCompanyContext, checkAuthentication, login, logout, register, isInitialized, isLoggedIn, user]
    );

    useEffect(() => {
        if (!location.pathname.includes(config.publicPath)) {
            checkAuthentication().then(() => {});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!location.pathname.includes(config.publicPath) && !isInitialized) {
        return <Loader />;
    }

    return <SanctumContext.Provider value={value}>{children}</SanctumContext.Provider>;
};

export default SanctumContext;
