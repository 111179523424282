import { PropsWithChildren, FC } from 'react';
import { Fade } from '@material-ui/core';
import { Box, Button, Stack } from '@mui/material';
import SectionHeading from '../../views/calendar/appointment-card/appointment-details/elements/SectionHeading';
import { SxProps } from '@material-ui/system';

type ConditionalDialogProps = {
    open: boolean;
    onCancel: () => void;
    onOk?: () => void;
    formId?: string;
    okButtonLabel?: string;
    cancelBtnLabel?: string;
    title?: string;
    okBtnDisabled?: boolean;
    cancelBtnDisabled?: boolean;
    actionsRowSx?: SxProps;
    headerSx?: SxProps;
};

const ConditionalDialog: FC<PropsWithChildren<ConditionalDialogProps>> = ({
    children,
    open,
    title,
    onCancel,
    onOk,
    formId,
    okButtonLabel = 'ОК',
    cancelBtnLabel = 'Отмена',
    okBtnDisabled = false,
    cancelBtnDisabled = false,
    actionsRowSx = {},
    headerSx = {}
}) => (
    <Fade in={open} unmountOnExit mountOnEnter>
        <Stack spacing={2}>
            {title ? <SectionHeading sx={headerSx}>{title}</SectionHeading> : null}
            <Box>{children}</Box>
            <Stack direction="row" spacing={1} justifyContent="flex-end" sx={actionsRowSx}>
                <Button variant="outlined" onClick={onCancel} disabled={cancelBtnDisabled}>
                    {cancelBtnLabel}
                </Button>
                {onOk || formId ? (
                    <Button
                        variant="contained"
                        type={formId ? 'submit' : 'button'}
                        form={formId}
                        onClick={onOk}
                        disableElevation
                        disabled={okBtnDisabled}
                    >
                        {okButtonLabel}
                    </Button>
                ) : null}
            </Stack>
        </Stack>
    </Fade>
);

export default ConditionalDialog;
