import { FC } from 'react';
import { TableContainer, Table, TableBody, TableHead, TableRow, TableCell, Typography, CircularProgress } from '@mui/material';
import { IAppointmentProduct } from '../../../../../../../models/IProduct';
import ActionsCell from '../payments/elements/payments-table/elements/ActionsCell';
import NumberFormat from 'react-number-format';
import { useAppSelector } from '../../../../../../../hooks/redux';

interface IAppointmentProductsTableProps {
    products: IAppointmentProduct[];
    isLoading?: boolean;
    onDelete: (id: number) => void;
    onUpdate: (data: IAppointmentProduct) => void;
}

const AppointmentProductsTable: FC<IAppointmentProductsTableProps> = ({ products, isLoading, onDelete, onUpdate }) => {
    const { isForeignAppointment } = useAppSelector((state) => state.calendar);

    return (
        <TableContainer>
            <Table size="small" stickyHeader>
                <TableHead
                    sx={{
                        '& .MuiTableCell-head': {
                            background: 'transparent'
                        }
                    }}
                >
                    <TableRow>
                        <TableCell>Продукт</TableCell>
                        <TableCell>Количество</TableCell>
                        <TableCell>Цена</TableCell>
                        <TableCell align="right" />
                    </TableRow>
                </TableHead>
                {isLoading && (
                    <TableBody>
                        <TableRow>
                            <TableCell colSpan={4} align="center" height={100}>
                                <CircularProgress />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                )}
                {!isLoading && (
                    <TableBody>
                        {products.length ? (
                            products.map((product) => (
                                <TableRow key={product.id}>
                                    <TableCell>{product.name}</TableCell>
                                    <TableCell>{product.pivot.amount}</TableCell>
                                    <TableCell>
                                        <NumberFormat
                                            displayType="text"
                                            value={product.pivot.price}
                                            suffix=" ₽"
                                            decimalScale={2}
                                            fixedDecimalScale
                                        />
                                    </TableCell>
                                    <TableCell align="right">
                                        {isForeignAppointment ? null : (
                                            <ActionsCell
                                                entity="Продукт"
                                                onEdit={() => onUpdate(product)}
                                                onDelete={() => onDelete(product.id)}
                                            />
                                        )}
                                    </TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={4}>
                                    <Typography fontSize={22} textAlign="center" py={2}>
                                        Нет продуктов
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                )}
            </Table>
        </TableContainer>
    );
};

export default AppointmentProductsTable;
