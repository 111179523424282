import { FC, MouseEvent, useCallback } from 'react';
import { Box } from '@material-ui/system';
import { SearchOptionProps } from '../models';
import { Avatar, IconButton, MenuItem, Typography } from '@mui/material';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import SendIcon from '@material-ui/icons/Send';
import { stringToColor } from '../../../store/constant';
import OptionTextWrapper from './OptionTextWrapper';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch } from '../../../hooks/redux';
import { DialogTypes, openDialog } from '../../../store/slices/entityDialogsSlice';

const CustomerOption: FC<SearchOptionProps> = ({ option, onClick, isMobile, onCreateAppointment }) => {
    const [, setSearchParams] = useSearchParams();
    const dispatch = useAppDispatch();

    const handleAddCustomer = useCallback(
        (e: MouseEvent) => {
            e.stopPropagation();
            onCreateAppointment?.(option.id);
        },
        [onCreateAppointment, option.id]
    );

    const canSendMessage = option.details.phone && !!option.details.upcoming_appointment_id;
    const handleMessageCustomer = useCallback(
        (e: MouseEvent) => {
            e.stopPropagation();
            if (canSendMessage) {
                const id = option.details.upcoming_appointment_id ?? null;
                setSearchParams(new URLSearchParams({ tab: 'messages' }));
                dispatch(openDialog({ type: DialogTypes.Appointment, id }));
            }
        },
        [canSendMessage, dispatch, option.details.upcoming_appointment_id, setSearchParams]
    );

    return (
        <MenuItem
            sx={{ paddingTop: 1, paddingBottom: 1, '&:hover': { background: 'rgba(237, 231, 246, 0.70)' } }}
            onClick={() => onClick(option.id)}
        >
            <Box
                sx={{
                    whiteSpace: 'normal',
                    width: '100%',
                    display: 'grid',
                    gridGap: '8px',
                    gridTemplateColumns: `28px ${isMobile ? 90 : 140}px 1fr ${canSendMessage ? '28px' : ''} ${
                        onCreateAppointment ? '28px' : ''
                    } `,
                    alignItems: 'center'
                }}
            >
                <Avatar sx={{ width: '28px', height: '28px', bgcolor: stringToColor(option.label), color: '#fff' }}>
                    <Typography>{option.label.charAt(0)}</Typography>
                </Avatar>
                <OptionTextWrapper>{option.label}</OptionTextWrapper>
                <OptionTextWrapper>{option.details.email ? option.details.email : option.details.phone}</OptionTextWrapper>
                {canSendMessage ? (
                    <IconButton size="small" color="primary" sx={{ p: 0, width: '28px', height: '28px' }} onClick={handleMessageCustomer}>
                        <SendIcon />
                    </IconButton>
                ) : null}
                {onCreateAppointment ? (
                    <IconButton size="small" color="primary" sx={{ p: 0, width: '28px', height: '28px' }} onClick={handleAddCustomer}>
                        <AddCircleOutlineIcon />
                    </IconButton>
                ) : null}
            </Box>
        </MenuItem>
    );
};
export default CustomerOption;
