import { useCallback } from 'react';
import { UserRole } from '../../../../models/IEmployee';
import { IconButton, Tooltip } from '@mui/material';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import useAuth from '../../../../hooks/useAuth';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import appointmentAPI from '../../../../services/AppointmentService';
import { openConfirmPopup } from '../../../../store/confirmPopupSlice';
import useShowSnackbar from '../../../../hooks/useShowSnackbar';
import { SnackBarTypes } from '../../../../store/snackbarReducer';
import { startSubmitting, stopSubmitting } from '../../../../store/slices/SubmittingSlice';

interface DeleteButtonProps {
    onSuccess: () => void;
    disabled?: boolean;
}

const DeleteButton = ({ onSuccess, disabled }: DeleteButtonProps) => {
    const { user } = useAuth();
    const { isSubmitting } = useAppSelector((state) => state.submitting);

    const dispatch = useAppDispatch();
    const { showSnackbar } = useShowSnackbar();
    const { selectedEvent } = useAppSelector((state) => state.calendar);

    const [deleteAppointment] = appointmentAPI.useDeleteAppointmentMutation();

    const handleDelete = useCallback(() => {
        if (!selectedEvent || isSubmitting) return;

        dispatch(startSubmitting());

        deleteAppointment(selectedEvent.id)
            .unwrap()
            .then(() => {
                showSnackbar({
                    message: 'Запись удалена.',
                    alertSeverity: SnackBarTypes.Success
                });
                onSuccess();
            })
            .catch((e) => {
                showSnackbar({
                    message: e.message || 'Запись не была удалена.',
                    alertSeverity: SnackBarTypes.Error
                });
            })
            .finally(() => dispatch(stopSubmitting()));
    }, [deleteAppointment, dispatch, isSubmitting, onSuccess, selectedEvent, showSnackbar]);

    const handleClick = useCallback(() => {
        dispatch(
            openConfirmPopup({
                onConfirm: () => handleDelete(),
                confirmText: `Удалить`,
                text: 'Вы действительно хотите удалить запись?'
            })
        );
    }, [dispatch, handleDelete]);

    return user && user.employee.role.name !== UserRole.Provider ? (
        <Tooltip title="Удалить запись">
            <span>
                <IconButton
                    disabled={isSubmitting || disabled}
                    onClick={handleClick}
                    color="error"
                    sx={{ border: '1px solid currentColor', borderRadius: 1 }}
                >
                    <DeleteIcon />
                </IconButton>
            </span>
        </Tooltip>
    ) : null;
};

export default DeleteButton;
