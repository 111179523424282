import AppointmentDetailsDialog from '../appointmant-details-dialog/AppointmentDetailsDialog';
import CustomerInfoDialog from '../../views/customer/customer-info/CustomerInfoModal';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { closeDialog, DialogTypes } from '../../store/slices/entityDialogsSlice';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { Box, Drawer } from '@mui/material';
import { ForceMobileLayoutProvider } from '../force-mobile-layout-context';
import BlockTimeDialog from '../../views/calendar/appointment-block/BlockTimeDialog';
import { Theme, useMediaQuery } from '@material-ui/core';
import {
    setAppointmentDetailsFormId,
    setIsForeignAppointment,
    setSelectedEvent as setStoreEvent,
    setSelectedRange
} from '../../store/slices/calendarSlice';
import InviteCreationDialog from '../../views/calendar/invite-creation-dialog';
import NewAppointmentModal from '../../views/calendar/new-appointment-modal';
import moment from 'moment-timezone';
import { clearOuterAppointment } from '../../store/slices/outerAppointmentSlice';
import useAuth from '../../hooks/useAuth';
import { useLocation } from 'react-router';
import { openConfirmPopup } from '../../store/confirmPopupSlice';
import { useBlocker } from 'react-router-dom';
import { setSelectedCustomerId } from '../../store/slices/calendarFilterSlice';

const sidebarDialogTypes = [DialogTypes.Appointment, DialogTypes.Invite, DialogTypes.BlockTime, DialogTypes.Customer];
const EntityDialogs = () => {
    const location = useLocation();
    const { user } = useAuth();
    const isDesktop = useMediaQuery((themeParam: Theme) => themeParam.breakpoints.up('lg'));
    const matchSm = useMediaQuery((themeParam: Theme) => themeParam.breakpoints.down('sm'));
    const isMobile = useMediaQuery('(max-width:768px)');

    const { entityId, entityType } = useAppSelector((state) => state.entityDialogs);
    const { selectedLocation, cellData, selectedCustomerId } = useAppSelector((state) => state.calendarFilter);
    const { selectedRange, selectedEmployeeId, shouldSubmitFormOnTabChange } = useAppSelector((state) => state.calendar);

    const inviteDuration = useMemo(() => {
        const calendarMinDuration = cellData.duration === '01:00:00' ? 60 : 30;
        return calendarMinDuration === selectedRange?.duration ? undefined : selectedRange?.duration;
    }, [cellData.duration, selectedRange]);

    const newAppointmentSelectedRangeEnd = useMemo(() => {
        const calendarMinDuration = cellData.duration === '01:00:00' ? 60 : 30;
        if (selectedRange) {
            const start = moment(selectedRange.start);
            const end = moment(selectedRange.end);
            const diff = end.diff(start, 'minutes');

            return diff !== calendarMinDuration ? selectedRange.end : undefined;
        }

        return undefined;
    }, [cellData.duration, selectedRange]);

    const dispatch = useAppDispatch();

    const handleClose = useCallback(() => {
        dispatch(closeDialog());

        // From old calendar action
        dispatch(clearOuterAppointment());
        dispatch(setIsForeignAppointment(false));
        dispatch(setSelectedRange(null));

        // Clearing appointment edit state
        dispatch(clearOuterAppointment());
        dispatch(setStoreEvent(null));

        // Clear details form id
        dispatch(setAppointmentDetailsFormId(undefined));

        // Clear selected customer id
        dispatch(setSelectedCustomerId(null));
    }, [dispatch]);

    const userModalViewEnabled = user ? user?.currentCompany.settings?.appointments.use_modal_view : true;
    const useAlternativeAppointmentsView = useMemo(() => isDesktop && !userModalViewEnabled, [isDesktop, userModalViewEnabled]);

    const open = useMemo(() => !!entityType && sidebarDialogTypes.includes(entityType) && useAlternativeAppointmentsView, [
        entityType,
        useAlternativeAppointmentsView
    ]);

    const dialogContainerRef = useRef<HTMLDivElement | null>(null);

    const container = useMemo(() => (useAlternativeAppointmentsView ? dialogContainerRef?.current : null), [
        useAlternativeAppointmentsView
    ]);

    const blocker = useBlocker(
        ({ currentLocation, nextLocation }) =>
            currentLocation.pathname !== nextLocation.pathname && shouldSubmitFormOnTabChange && !!entityId && entityType === 'appointment'
    );

    useEffect(() => {
        if (blocker.state === 'blocked') {
            dispatch(
                openConfirmPopup({
                    text: 'Сбросить несохраненные изменения?',
                    confirmText: 'Сбросить',
                    onClose: () => {
                        blocker.reset();
                    },
                    onConfirm: () => {
                        blocker.proceed();
                        dispatch(closeDialog());
                    }
                })
            );
        }
    }, [blocker, dispatch]);

    useEffect(() => {
        if (entityType) {
            dispatch(closeDialog());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    return (
        <Box className={open ? 'openRightDrawer' : undefined} sx={{ width: open ? '400px' : 0, flexShrink: 0 }}>
            <Drawer
                anchor="right"
                className="entityDetailsDrawer"
                variant="persistent"
                open={open}
                ModalProps={{
                    keepMounted: false
                }}
                sx={{
                    height: 'calc(100vh - 80px)',
                    '& .MuiDrawer-paper': { top: '80px', width: '400px', height: 'calc(100vh - 80px)' }
                }}
            >
                <ForceMobileLayoutProvider value={open}>
                    <Box
                        ref={dialogContainerRef}
                        sx={{
                            overflow: 'auto !important',
                            '& .MuiModal-root': { position: 'static' },
                            '& .MuiBackdrop-root': { display: 'none' },
                            '& .MuiDialog-paper': {
                                margin: 0,
                                borderRadius: '0 !important',
                                boxShadow: 'none',
                                width: '100%'
                            },
                            '& .MuiDialog-paper.MuiDialog-paperScrollPaper': {
                                height: 'calc(100vh - 80px)'
                            }
                        }}
                    >
                        {/* Appointment Details */}
                        <AppointmentDetailsDialog
                            id={entityType === 'appointment' ? entityId : null}
                            onClose={handleClose}
                            container={container}
                        />

                        {/* New Appointment */}
                        {selectedLocation ? (
                            <NewAppointmentModal
                                location={selectedLocation}
                                employeeId={selectedEmployeeId}
                                isOpen={entityType === 'appointment' && !entityId}
                                onClose={handleClose}
                                startDate={selectedRange?.start}
                                endDate={!isMobile ? newAppointmentSelectedRangeEnd : undefined}
                                container={container}
                                customerId={selectedCustomerId}
                            />
                        ) : null}

                        {/* Appointment Invite */}
                        {entityType === 'invite' && !entityId ? (
                            <InviteCreationDialog
                                onClose={handleClose}
                                duration={inviteDuration || 0}
                                employeeId={selectedEmployeeId || 0}
                                locationId={selectedLocation?.id || 0}
                                container={container}
                            />
                        ) : null}

                        {/* Time Block */}
                        {selectedLocation ? (
                            <BlockTimeDialog
                                isOpen={entityType === 'block_time'}
                                onClose={handleClose}
                                eventId={entityId && entityType === 'block_time' ? entityId : undefined}
                                range={selectedRange}
                                location={selectedLocation}
                                matchSm={!!container || matchSm}
                                selectedEmployeeId={selectedEmployeeId}
                                container={container}
                            />
                        ) : null}

                        {/* Customer Info */}
                        {entityType === 'customer' && !!entityId && (
                            <CustomerInfoDialog customerId={String(entityId)} onClose={handleClose} container={container} />
                        )}
                    </Box>
                </ForceMobileLayoutProvider>
            </Drawer>
        </Box>
    );
};

export default EntityDialogs;
