import React, { FC, PropsWithChildren, useCallback } from 'react';
import moment, { Moment } from 'moment-timezone';

// material-ui
import { FormControl, FormHelperText, Button, CardActions, CardContent, Divider, Grid, Typography } from '@mui/material';
import { MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

// project imports
import InputLabel from 'ui-component/extended/Form/InputLabel';
import { ICustomerPayload } from 'models/ICustomer';
import OptimizedTextField from '../../ui-component/optimized-text-fields/OptimizedTextField';
import CustomPhoneInput from '../../ui-component/form/CustomPhoneInput';
import AppointmentAddressBox from '../../ui-component/AppointmentAddressBox';
import { IAddress } from '../../models/IAddress';
import EmployeeIdSelect from '../../ui-component/form/EmployeeIdSelect';
import useCustomerFormValidation from './hooks/use-customer-form-validation';
import useCanSeeCustomerDetails from '../../hooks/use-can-see-customer-details';
import useExtendedFormik from '../../hooks/useExtendedFormik';

interface CustomerFullFormProps {
    customer: ICustomerPayload;
    update: (customer: ICustomerPayload) => void;
}

const addressLabels = {
    address: 'Адрес',
    state: 'Штат',
    city: 'Город',
    postal_code: 'Почтовый индекс'
} as const;

const CustomerFormRow: FC<PropsWithChildren<{ label: string }>> = ({ children, label }) => (
    <>
        <Grid item xs={12} sm={3} lg={4} sx={{ pt: { xs: 2, sm: '0 !important' } }}>
            <InputLabel horizontal>{label}</InputLabel>
        </Grid>
        <Grid item xs={12} sm={9} lg={6}>
            {children}
        </Grid>
    </>
);

const CustomerFormDivider: FC<{ label: string }> = ({ label }) => (
    <>
        <Grid item xs={12} lg={4} sx={{ pt: { xs: 2, sm: '0 !important' } }}>
            <Typography sx={{ mt: 2 }} color="secondary" fontSize="medium" fontWeight="bold">
                {label}:
            </Typography>
        </Grid>
        <Grid item xs={12} lg={6}>
            <Divider />
        </Grid>
    </>
);

const CustomerFullForm: React.FC<CustomerFullFormProps> = ({ customer, update }) => {
    moment.tz.setDefault(moment.tz.guess());
    const { canEditCustomerOwner } = useCanSeeCustomerDetails();
    const { schema } = useCustomerFormValidation();

    const {
        handleSubmit,
        values,
        touched,
        errors,
        handleBlur,
        handleChange,
        setFieldValue,
        setFieldTouched
    } = useExtendedFormik<ICustomerPayload>({
        enableReinitialize: true,
        initialValues: customer,
        validationSchema: schema,
        onSubmit: update
    });

    const isAddressFieldTouched = useCallback(
        (field?: keyof IAddress): boolean => {
            if (field && !!touched.address && typeof touched.address === 'object') {
                return touched.address[field];
            }

            return Boolean(touched.address);
        },
        [touched.address]
    );

    return (
        <form noValidate onSubmit={handleSubmit}>
            <CardContent sx={{ px: { xs: 0, sm: 2 }, pt: { xs: 0, sm: 2 } }}>
                <Grid container spacing={2} alignItems="center">
                    <CustomerFormRow label="Имя">
                        <FormControl fullWidth>
                            <OptimizedTextField
                                id="firstname"
                                name="firstname"
                                placeholder="Имя"
                                value={values.firstname}
                                onBlur={handleBlur}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    handleChange(e);
                                    setFieldTouched('firstname', false);
                                }}
                                error={Boolean(touched.firstname && errors.firstname)}
                            />
                            {touched.firstname && errors.firstname && <FormHelperText error>{errors.firstname}</FormHelperText>}
                        </FormControl>
                    </CustomerFormRow>

                    <CustomerFormRow label="Фамилия">
                        <FormControl fullWidth>
                            <OptimizedTextField
                                id="lastname"
                                name="lastname"
                                placeholder="Фамилия"
                                value={values.lastname}
                                onBlur={handleBlur}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    handleChange(e);
                                    setFieldTouched('lastname', false);
                                }}
                                error={Boolean(touched.lastname && errors.lastname)}
                            />
                            {touched.lastname && errors.lastname && <FormHelperText error>{errors.lastname}</FormHelperText>}
                        </FormControl>
                    </CustomerFormRow>

                    <CustomerFormRow label="Email">
                        <FormControl fullWidth>
                            <OptimizedTextField
                                id="email"
                                name="email"
                                placeholder="Email"
                                value={values.email}
                                onBlur={handleBlur}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    handleChange(e);
                                    setFieldTouched('email', false);
                                }}
                                error={Boolean(touched.email && errors.email)}
                            />
                            {touched.email && errors.email && <FormHelperText error>{errors.email}</FormHelperText>}
                        </FormControl>
                    </CustomerFormRow>

                    <CustomerFormRow label="Телефон">
                        <CustomPhoneInput
                            id="phone"
                            name="phone"
                            placeholder="Телефон"
                            value={values.phone ?? ''}
                            onChange={(v) => {
                                setFieldTouched('phone', false);
                                setFieldValue('phone', v);
                            }}
                            onBlur={handleBlur}
                            error={Boolean(touched.phone && errors.phone)}
                            helperText={touched.phone ? errors.phone : undefined}
                            fullWidth
                        />
                    </CustomerFormRow>
                </Grid>
            </CardContent>
            <Divider />
            <CardActions sx={{ px: { xs: 0, sm: 2 } }}>
                <Grid container spacing={1}>
                    <Grid item>
                        <Button type="submit" variant="contained" color="primary">
                            Сохранить
                        </Button>
                    </Grid>
                </Grid>
            </CardActions>
        </form>
    );
};

export default CustomerFullForm;
