import { useEffect, useMemo } from 'react';

// material-ui
import { Typography, CircularProgress, Button, Box } from '@material-ui/core';
import Transitions from '../../../../ui-component/extended/Transitions';

// project imports
import { IEmployee } from '../../../../models/IEmployee';
import { ProviderFormProps } from '../types';
import appointmentWidgetAPI from '../../../../services/WidgetService';
import { useAppDispatch } from '../../../../hooks/redux';

// third-party
import * as Yup from 'yup';
import StepTitle from '../../components/StepTitle';
import WidgetButton from '../../components/WidgetButton';
import { styled } from '@material-ui/core/styles';
import preferredImageSize from '../../../../utils/preferred-image-size';
import useWidgetOptions from '../../hooks/useWidgetOptions';
import useExtendedFormik from '../../../../hooks/useExtendedFormik';
import { useParams } from 'react-router-dom';

const validationSchema = Yup.object({
    provider: Yup.object().nullable().required('Выберите исполнителя')
});

const StyledAnyButton = styled(Button)(({ theme }) => ({
    width: '100%',
    mx: 'auto',
    marginBottom: theme.spacing(1),
    padding: theme.spacing(2, 0),
    color: theme.palette.widget.green,
    borderColor: theme.palette.widget.green,

    '&:hover': {
        borderColor: theme.palette.widget.green,
        background: 'none'
    }
}));

const ProviderForm = ({
    step,
    addProgress,
    substituteProgress,
    serviceData,
    locationData,
    setProviderData,
    providerData,
    setDateData,
    handleNext,
    isAnyProvider,
    setIsAnyProvider,
    handleBack,
    filteredEmployees,
    submitted
}: ProviderFormProps) => {
    const dispatch = useAppDispatch();
    const { company_slug } = useParams();
    const { options, isLoading } = useWidgetOptions(
        {
            services: serviceData.map(({ slug }) => slug),
            locations: locationData?.slug ? [locationData.slug] : undefined,
            company_slug
        },
        !serviceData || !locationData
    );
    const providers = useMemo(() => filteredEmployees || options.employees, [filteredEmployees, options]);

    const { errors, setFieldValue, handleSubmit, values } = useExtendedFormik({
        enableReinitialize: true,
        initialValues: {
            provider: providerData
        },
        validationSchema,
        onSubmit: (formData) => {
            if (formData.provider) {
                if (providerData && providerData.id !== formData.provider.id) {
                    setDateData(null);
                }
                setProviderData(formData.provider);
                handleNext();
            }
        }
    });

    const handleSelectProvider = (provider: IEmployee) => {
        setFieldValue('provider', provider);
        if (providerData && providerData.id !== provider.id) {
            setProviderData(null);
            setDateData(null);
            substituteProgress(step);
            dispatch(appointmentWidgetAPI.util.invalidateTags(['Widget Slots']));
            setProviderData(provider);
            handleNext();
        } else {
            addProgress(step);
            setProviderData(provider);
            handleNext();
        }
    };

    useEffect(() => {
        if (!providerData && providers && providers.length === 1) {
            setProviderData(providers[0]);
            addProgress(step);
            handleNext();
        }
    }, [addProgress, handleNext, providerData, providers, setProviderData, step]);

    return (
        <Transitions type="fade" in>
            <StepTitle error={!!errors.provider} title="Выберите исполнителя" step={step} handleBack={handleBack} submitted={submitted} />
            {isLoading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress />
                </Box>
            ) : (
                <form onSubmit={handleSubmit} id={`widget-form-${step}`}>
                    {!isLoading &&
                        providers &&
                        providers.length > 0 &&
                        [...providers]
                            .sort(() => Math.random() - 0.5)
                            .map((provider) => {
                                const filteredServices = provider?.services.filter((service) =>
                                    serviceData?.some((s) => s.id === service.id)
                                );
                                const totalPrice = filteredServices.reduce(
                                    (acc, curr) =>
                                        acc +
                                        +(curr.pivot?.price
                                            ? curr.pivot?.price
                                            : serviceData.find((service) => service?.id == curr.id)?.price ?? 0),
                                    0
                                );

                                return (
                                    <WidgetButton
                                        key={`provider_btn_${provider.id}`}
                                        isSelected={values.provider?.id === provider.id}
                                        imageUrl={preferredImageSize(provider.avatar || provider.user.avatar, 'small')}
                                        name={`${provider?.user?.title} (${totalPrice} ₽)`}
                                        bottomContent={provider.profession_title ? <i>{provider.profession_title}</i> : null}
                                        onChoose={() => {
                                            handleSelectProvider(provider);
                                            if (isAnyProvider) {
                                                setIsAnyProvider(false);
                                            }
                                        }}
                                    />
                                );
                            })}
                    {!isLoading && providers && providers.length > 1 && (
                        <StyledAnyButton
                            onClick={() => {
                                handleSelectProvider({} as IEmployee);
                                setIsAnyProvider(true);
                            }}
                            variant={values.provider && Object.keys(values.provider).length === 0 ? 'contained' : 'outlined'}
                        >
                            Любой
                        </StyledAnyButton>
                    )}
                    {!isLoading && providers && providers.length === 0 && (
                        <Typography>Нет доступных исполнителей для данной локации</Typography>
                    )}
                </form>
            )}
        </Transitions>
    );
};

export default ProviderForm;
