import * as Yup from 'yup';
import { isValidPhone } from '../../../utils/phone-helpers';
import { useMemo } from 'react';
import moment from 'moment-timezone';
import regExps from '../../../utils/formValidators';

const useCustomerFormValidation = () => {
    const schema = useMemo(
        () =>
            Yup.object().shape(
                {
                    firstname: Yup.string().trim().max(255, 'Имя не может быть более 255 символов').required('Введите имя'),
                    lastname: Yup.string().trim().nullable().max(255, 'Фамилия не может быть более 255 символов'),
                    email: Yup.string().trim().nullable().max(255, 'Email не может быть более 255 символов').email('Неверный email'),
                    /* .when('phone', {
                            is: (phone: string) => !phone || phone.length === 0,
                            then: Yup.string().nullable().required('Введите email или телефон')
                        }), */
                    phone: Yup.string()
                        .trim()
                        .test({
                            name: 'phone',
                            test: (v) => (v ? isValidPhone(v) : true),
                            message: 'Неверный телефон'
                        }),
                    /* .when('email', {
                            is: (email: string) => !email || email.length === 0,
                            then: Yup.string().trim().required('Введите email или телефон')
                        }), */
                    birth_date: Yup.date()
                        .nullable()
                        .typeError('Неверная дата')
                        .min(moment('01-01-1900', 'MM-DD-YYYY').toDate(), 'Минимальная дата 01.01.1900')
                        .max(moment().add(1, 'hour').toDate(), `Максимальная дата ${moment().format('MM/DD/YYYY')}`),
                    address: Yup.object()
                        .shape({
                            address: Yup.string().trim().nullable().max(255, 'Лимит 255 символов'),
                            city: Yup.string().trim().nullable().max(255, 'Лимит 255 символов'),
                            state: Yup.string().trim().nullable().max(255, 'Лимит 255 символов'),
                            postal_code: Yup.string()
                                .trim()
                                .nullable()
                                .max(255, 'Лимит 255 символов')
                                .matches(regExps.postal_code, 'Неверный почтовый индекс')
                        })
                        .nullable(),
                    note: Yup.string().trim().nullable(),
                    employee_owner_id: Yup.number().nullable()
                },
                [['email', 'phone']]
            ),
        []
    );

    return { schema };
};

export default useCustomerFormValidation;
