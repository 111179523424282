import { PayPalButtons, PayPalScriptProvider, usePayPalScriptReducer } from '@paypal/react-paypal-js';
import { IService } from '../../../../../../models/IService';
import { TimeSlot } from '../../../types';
import moment from 'moment';
import useShowSnackbar from '../../../../../../hooks/useShowSnackbar';
import { SnackBarTypes } from '../../../../../../store/snackbarReducer';
import { PaymentGateways } from '../../../../../../types';
import useDepositFunctions from '../../../../hooks/useDepositFunctions';
import { Box, Typography } from '@mui/material';

const PayPalLoadingError = () => {
    const [{ isRejected }] = usePayPalScriptReducer();

    return isRejected ? <Typography>Invalid paypal gateway configuration, please contact support.</Typography> : null;
};

interface PaypalFormProps {
    clientId: string;
    services: IService[];
    date: Pick<TimeSlot, 'start_at'> | null;
    submitBooking: (details?: object) => void;
    amount?: number;
    selfCheckout?: boolean;
    disabled?: boolean;
}

const PaypalForm = ({ clientId, services, date, submitBooking, amount, selfCheckout, disabled }: PaypalFormProps) => {
    const { showSnackbar } = useShowSnackbar();
    const { servicePrice } = useDepositFunctions();
    const isLocal = process.env.NODE_ENV === 'development';

    const price = amount || servicePrice;

    return (
        <PayPalScriptProvider
            options={{
                'client-id': clientId,
                intent: 'authorize',
                'enable-funding': 'venmo',
                'buyer-country': isLocal ? 'US' : undefined
            }}
        >
            <Box sx={{ position: 'relative', mb: 1.5 }} id="paypalContainer">
                <PayPalButtons
                    disabled={disabled}
                    style={{ layout: 'horizontal', tagline: false, height: !selfCheckout ? 45 : 54 }}
                    createOrder={(data, actions) =>
                        actions.order.create({
                            purchase_units: [
                                {
                                    amount: {
                                        value: `${price}`,
                                        breakdown: {
                                            item_total: {
                                                currency_code: 'USD',
                                                value: `${price}`
                                            }
                                        }
                                    },
                                    items: [
                                        {
                                            name: `Deposit Payment ${date ? moment(date.start_at).format('MMMM Do YYYY, hh:mm A') : ''}`,
                                            quantity: '1',
                                            unit_amount: {
                                                value: `${price}`,
                                                currency_code: 'USD'
                                            }
                                        }
                                    ]
                                }
                            ]
                        })
                    }
                    onApprove={(data, actions) =>
                        // @ts-ignore
                        actions.order.authorize().then((details) => {
                            submitBooking({ ...details, gateway: PaymentGateways.Paypal });
                        })
                    }
                    onError={(err) => {
                        showSnackbar({
                            message: 'Error occurred. Please try again or select another payment option.',
                            alertSeverity: SnackBarTypes.Error
                        });
                    }}
                />
                <PayPalLoadingError />
            </Box>
        </PayPalScriptProvider>
    );
};

export default PaypalForm;
