import { Box, Button, Stack } from '@mui/material';
import { FC, PropsWithChildren, useCallback } from 'react';
import useSwipe from '../../../../../../../../../hooks/useSwipe';

interface INewPaymentFormStepProps {
    label: string;
    handleNext?: () => void;
    handlePrev?: () => void;
    nextBlocked?: boolean;
    canSubmit?: boolean;
}

const NewPaymentFormStep: FC<PropsWithChildren<INewPaymentFormStepProps>> = ({
    label,
    children,
    handleNext,
    handlePrev,
    canSubmit,
    nextBlocked
}) => {
    const onSwipeRight = useCallback(() => {
        handlePrev?.();
    }, [handlePrev]);

    const onSwipeLeft = useCallback(() => {
        if (!nextBlocked) {
            handleNext?.();
        }
    }, [handleNext, nextBlocked]);

    const swipeProps = useSwipe({ onSwipeLeft, onSwipeRight });

    return (
        <Box {...swipeProps}>
            <Stack spacing={2}>
                <Box sx={{ textAlign: 'center' }}>{label}</Box>
                <Box>{children}</Box>
                {handleNext || handlePrev || canSubmit ? (
                    <Box sx={{ justifyContent: 'space-between', display: 'flex', alignItems: 'center' }}>
                        {handlePrev ? <Button onClick={handlePrev}>Назад</Button> : null}
                        {handleNext && !canSubmit ? (
                            <Button onClick={handleNext} disabled={!!nextBlocked}>
                                Далее
                            </Button>
                        ) : null}
                        {canSubmit ? (
                            <Button type="submit" form="new-payment-form">
                                Сохранить
                            </Button>
                        ) : null}
                    </Box>
                ) : null}
            </Stack>
        </Box>
    );
};

export default NewPaymentFormStep;
