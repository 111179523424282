import { Button, Grid } from '@material-ui/core';
import MainCard from 'ui-component/cards/MainCard';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../hooks/redux';

// assets
import ChevronLeftOutlinedIcon from '@material-ui/icons/ChevronLeftOutlined';
import customerAPI from 'services/CustomerService';
import { ICustomerPayload } from 'models/ICustomer';
import CustomerFullForm from './CustomerFullForm';
import { startSubmitting, stopSubmitting } from '../../store/slices/SubmittingSlice';
import getErrorMessage from '../../utils/get-error-message';
import useCustomerFormDefaults from './hooks/use-customer-form-defaults';
import useShowSnackbar from '../../hooks/useShowSnackbar';
import { SnackBarTypes } from '../../store/snackbarReducer';

const CustomerCreate: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [createCustomer] = customerAPI.useCreateCustomerMutation();
    const initValue = useCustomerFormDefaults();
    const { showSnackbar } = useShowSnackbar();

    const handleCreate = (customer: ICustomerPayload) => {
        dispatch(startSubmitting());
        createCustomer(customer)
            .unwrap()
            .then(() => {
                showSnackbar({
                    message: 'Клиент создан',
                    alertSeverity: SnackBarTypes.Success
                });
                navigate('/customer', { replace: true });
            })
            .catch((error) => {
                const message = getErrorMessage(error);
                showSnackbar({
                    message,
                    alertSeverity: SnackBarTypes.Error
                });
            })
            .finally(() => {
                dispatch(stopSubmitting());
            });
    };

    return (
        <Grid>
            <MainCard
                title="Новый клиент"
                secondary={
                    <Button size="small" disableElevation onClick={() => navigate(-1)}>
                        <ChevronLeftOutlinedIcon />
                        Назад
                    </Button>
                }
                contentSX={{ p: { xs: 1.5, sm: 3 } }}
            >
                <CustomerFullForm customer={initValue} update={handleCreate} />
            </MainCard>
        </Grid>
    );
};

export default CustomerCreate;
