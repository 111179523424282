import { useRef, useMemo, useEffect } from 'react';
import { skipToken } from '@reduxjs/toolkit/query/react';

// material-ui
import { Box, useMediaQuery } from '@mui/material';

// project imports
import WidgetWrapper from './WidgetWrapper';
import WidgetWizard from './widget-wizard/WidgetWizard';
import Head from 'utils/head';
import WidgetCardWrapper from './WidgetCardWrapper';
import appointmentWidgetAPI from '../../services/WidgetService';
import WidgetError from './widget-wizard/WidgetError';
import Loader from '../../ui-component/Loader';
import WidgetHeader from './components/WidgetHeader';
import WidgetThemeProvider from './WidgetThemeProvider';
import { useLocation, useNavigate } from 'react-router';
import preferredImageSize from '../../utils/preferred-image-size';

interface WidgetProps {
    companySlug?: string;
    useMobileStyles?: boolean;
}

const WidgetCore = ({ companySlug, useMobileStyles }: WidgetProps) => {
    const location = useLocation();
    const navigate = useNavigate();
    const scrollRef = useRef();

    const { refetch } = appointmentWidgetAPI.useGetCsrfCookiesQuery(null);

    useEffect(() => {
        const csrfRenew = setInterval(() => {
            refetch();
        }, 300000);

        return () => {
            clearInterval(csrfRenew);
        };
    }, [refetch]);

    const query = useMemo(() => {
        const path = location.pathname.split('/');
        path.splice(0, 3);
        return path.join('/');
    }, [location]);

    const { data, isLoading } = appointmentWidgetAPI.useGetWidgetCompanyWithQueryStringQuery(
        companySlug ? { slug: companySlug, query } : skipToken
    );

    const matchDownMedia = useMediaQuery('(max-width: 800px)');

    const matchDownMd = useMemo(() => useMobileStyles || matchDownMedia, [matchDownMedia, useMobileStyles]);

    const scrollToTop = () => {
        const curr: any = scrollRef.current;
        if (curr) {
            curr.scrollTop = 0;
        }
    };

    const imageUrl = useMemo(() => {
        if (data) {
            const logo = data.logo || data.logo_rectangular;
            return preferredImageSize(logo, 'small');
        }
        return undefined;
    }, [data]);

    useEffect(() => {
        const fields = ['filteredEmployees', 'filteredLocations', 'filteredServices'] as const;
        const hasEmptyFields = fields.some((field) => !!data?.[field] && !data?.[field]?.length);

        if (query && hasEmptyFields) {
            navigate(location.pathname.replace(query, ''), { replace: true });
        }
    }, [data, location.pathname, navigate, query]);

    return (
        <>
            {companySlug && data && (
                <WidgetThemeProvider styles={data?.settings.widget}>
                    <WidgetWrapper>
                        <Head title={`${data.name} Запись |`} />
                        <WidgetHeader name={data.name} imageUrl={imageUrl} />
                        <WidgetCardWrapper>
                            <WidgetWizard
                                company_slug={companySlug}
                                data={data}
                                matchDownMd={matchDownMd}
                                scrollToTop={scrollToTop}
                                query={query}
                            />
                        </WidgetCardWrapper>
                        <Box id="cb_widget_actions_portal" sx={{ position: 'sticky', bottom: 0, zIndex: 10 }} />
                    </WidgetWrapper>
                </WidgetThemeProvider>
            )}
            {isLoading && <Loader />}
            {!isLoading && !data && <WidgetError />}
        </>
    );
};

export default WidgetCore;
