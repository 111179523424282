import DeleteIcon from '@material-ui/icons/Delete';
import { IconButton } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import React from 'react';

interface DeleteIconButtonProps {
    onClick: () => void;
}

const DeleteIconButton = React.forwardRef(({ onClick }: DeleteIconButtonProps) => {
    const theme = useTheme();

    return (
        <IconButton
            onClick={onClick}
            color="primary"
            sx={{
                color: theme.palette.orange.dark,
                borderColor: theme.palette.orange.main,
                '&:hover ': { background: theme.palette.orange.light }
            }}
        >
            <DeleteIcon sx={{ fontSize: '1.1rem' }} />
        </IconButton>
    );
});

export default DeleteIconButton;
