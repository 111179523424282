import { FC } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import AdapterDateFnsWithLocale from './AdapterDateFnsWithLocale';
import { ShiftPickerContextProvider, IShiftPickerProps } from './shift-picker-context';
import ShiftPickerInput from './shift-picker-input';

const ShiftPicker: FC<IShiftPickerProps> = ({ value, onChange, excluded }) => (
    <ShiftPickerContextProvider pickerProps={{ value, onChange, excluded }}>
        <LocalizationProvider dateAdapter={AdapterDateFnsWithLocale}>
            <ShiftPickerInput />
        </LocalizationProvider>
    </ShiftPickerContextProvider>
);

export default ShiftPicker;
