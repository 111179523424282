import { Moment } from 'moment-timezone';
import { IEmployeeLocation } from '../models/IEmployee';
import { ILocation } from '../models/ILocation';
import { IService } from '../models/IService';
import { getShiftByDate } from '../store/constant';
import { union } from 'lodash';
import { getServiceHolidayIds } from '../utils/services';

const useShouldDisableDate = () => {
    const shouldDisableDate = (
        pickerDate: Moment,
        employee: {
            id: number;
            locations: Pick<IEmployeeLocation, 'id' | 'pivot' | 'schedule' | 'is_shifts_enabled' | 'shifts'>[];
        } | null,
        location: Pick<ILocation, 'id' | 'schedule'> | null,
        service: IService[] | null
    ) => {
        let employeeHolidaysIDs: number[] = [];
        let serviceHolidaysIDs: number[] = [];

        const usedLocation = location ? employee?.locations?.find((l) => l.id === location.id) : null;
        const schedule = usedLocation?.pivot.schedule ?? null;
        const is_shifts_enabled = usedLocation?.pivot.is_shifts_enabled ?? null;
        const shifts = usedLocation?.pivot.shifts ?? null;
        const location_shifts_enabled = !!usedLocation?.is_shifts_enabled;
        const location_shifts = usedLocation?.shifts;
        const use_location_schedule = usedLocation?.pivot.use_location_schedule ?? null;

        if (!usedLocation && employee?.locations?.length) {
            console.error(`Pivot for location #${location?.id} not found (method: useShouldDisableDate)`);
        }

        if (is_shifts_enabled && shifts) {
            const customShift = getShiftByDate(pickerDate, shifts);

            if (customShift) return !customShift.opened;
        }

        if (location_shifts && location_shifts_enabled && use_location_schedule) {
            const locationShift = getShiftByDate(pickerDate, location_shifts);
            if (locationShift) {
                return !locationShift.opened;
            }
        }

        if (employee && schedule) {
            const employeeHolidays = use_location_schedule
                ? location?.schedule.filter((day) => !day.enable)
                : schedule.filter((day) => !day.enable);

            employeeHolidaysIDs = employeeHolidays ? employeeHolidays.map(({ id }) => Number(id)) : [];
        }

        if (service) {
            serviceHolidaysIDs = getServiceHolidayIds(service);
        }
        return union(employeeHolidaysIDs, serviceHolidaysIDs).includes(pickerDate.day());
    };

    return { shouldDisableDate };
};

export default useShouldDisableDate;
