import { FC } from 'react';
// import { Box, IconButton, Stack, Tooltip } from '@mui/material';
import { IconButton, Tooltip } from '@mui/material';
import useCustomShiftConversions from '../../../hooks/useCustomShiftConversions';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { useShiftPickerContext } from './shift-picker-context';

const ShiftPickerValue: FC = () => {
    const ctx = useShiftPickerContext();
    const value = ctx?.value || [];
    const onToggle = ctx?.onToggle;

    const { getSelectionDetails } = useCustomShiftConversions();

    return (
        // <Stack direction="row" spacing={1} alignItems="center">
        //     <Tooltip title={getSelectionDetails(value).details ?? ''}>
        //         <Box sx={{ display: 'flex', alignItems: 'center' }}>{getSelectionDetails(value).label}</Box>
        //     </Tooltip>
        //     <IconButton color="primary" size="small" aria-label="Open Datepicker" onClick={onToggle}>
        //         <CalendarTodayIcon />
        //     </IconButton>
        // </Stack>
        <Tooltip title={getSelectionDetails(value).details ?? ''}>
            <IconButton color="primary" size="small" aria-label="Open Datepicker" onClick={onToggle}>
                <CalendarTodayIcon />
            </IconButton>
        </Tooltip>
    );
};

export default ShiftPickerValue;
