import React, { FC, useCallback, useMemo, useRef, useState } from 'react';

// MUI
import { Box, Chip, ClickAwayListener, Grid, IconButton, Popper, TextField } from '@mui/material';
import VideoCallTwoToneIcon from '@material-ui/icons/VideoCallTwoTone';
import AttachmentTwoToneIcon from '@material-ui/icons/AttachmentTwoTone';
import SendTwoToneIcon from '@material-ui/icons/SendTwoTone';
import MoodTwoToneIcon from '@material-ui/icons/MoodTwoTone';

// Third Party
import { useDropzone } from 'react-dropzone';
import Picker, { IEmojiData, SKIN_TONE_NEUTRAL } from 'emoji-picker-react';

// Project
import MainCard from '../cards/MainCard';
import { SnackBarTypes } from '../../store/snackbarReducer';
import useShowSnackbar from '../../hooks/useShowSnackbar';
import appointmentAPI from '../../services/AppointmentService';
import { useAppSelector } from '../../hooks/redux';
import useMessagesCache from '../../hooks/use-messages-cache';

const ChatInput: FC<{ conversationId?: number }> = ({ conversationId }) => {
    const [message, setMessage] = useState('');
    const [attachment, setAttachment] = useState<File | undefined>(undefined);
    const [loading, setLoading] = useState(false);
    const [send] = appointmentAPI.useSendCommentMutation();
    const [createConversation] = appointmentAPI.useCreateNewConversationMutation();
    const { selectedEvent } = useAppSelector((store) => store.calendar);

    const { showSnackbar } = useShowSnackbar();
    const { updateMessagesCache } = useMessagesCache();

    const onEmojiClick = (event: React.MouseEvent<Element, MouseEvent>, emojiObject: IEmojiData) => {
        setMessage(message + emojiObject.emoji);
    };

    const [anchorElEmoji, setAnchorElEmoji] = React.useState<any>();
    /** No single type can cater for all elements */
    const handleOnEmojiButtonClick = (event: React.MouseEvent<HTMLButtonElement> | undefined) => {
        setAnchorElEmoji(anchorElEmoji ? null : event?.currentTarget);
    };

    const emojiOpen = Boolean(anchorElEmoji);
    const emojiId = emojiOpen ? 'simple-popper' : undefined;
    const handleCloseEmoji = () => {
        setAnchorElEmoji(null);
    };

    const videoCallLink = useMemo(() => {
        if (selectedEvent?.cbvc_url) {
            return selectedEvent.cbvc_url;
        }

        return null;
    }, [selectedEvent]);

    const inputRef = useRef<HTMLInputElement | null>(null);

    const copyVideoCallLink = useCallback(() => {
        if (videoCallLink) {
            setMessage((prevState) => {
                const beforeCursor = prevState.slice(0, inputRef.current?.selectionStart ?? 0).trim();
                const afterCursor = prevState.slice(inputRef.current?.selectionEnd ?? 0).trim();
                return `${beforeCursor} ${videoCallLink} ${afterCursor}`.trim();
            });
        }
    }, [videoCallLink]);

    const { getRootProps, getInputProps } = useDropzone({
        multiple: false,
        noDrag: true,
        disabled: loading,
        onDrop: (acceptedFiles, fileRejections) => {
            setAttachment(acceptedFiles[0]);
            if (fileRejections.length) {
                const [error] = fileRejections;
                showSnackbar({
                    alertSeverity: SnackBarTypes.Error,
                    message: error.errors.map((err) => err.message).join(', ')
                });
            }
        },
        maxSize: 1000 * 1024 //  1mb
    });

    const appointmentId = selectedEvent?.id;

    const canSendMessage = useMemo(() => !loading && (!!message.trim() || attachment), [attachment, loading, message]);

    const handleSendMessage = useCallback(async () => {
        if (appointmentId) {
            setLoading(true);
            const payload = {
                appointmentId,
                text: message.trim(),
                attachment
            };

            (conversationId ? send({ ...payload, conversationId }) : createConversation(payload))
                .unwrap()
                .then((res) => {
                    setMessage('');

                    setAttachment(undefined);
                    updateMessagesCache(appointmentId, res);
                })
                .catch((err) => {
                    showSnackbar({
                        alertSeverity: SnackBarTypes.Error,
                        message: err.message ?? err.data ?? JSON.stringify(err)
                    });
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [appointmentId, attachment, conversationId, createConversation, message, send, showSnackbar, updateMessagesCache]);

    return (
        <Box sx={{ px: 1 }}>
            <Grid container spacing={1} alignItems="center">
                {videoCallLink && (
                    <Grid item>
                        <IconButton size="small" onClick={copyVideoCallLink} disabled={loading}>
                            <VideoCallTwoToneIcon />
                        </IconButton>
                    </Grid>
                )}

                <Grid item>
                    <IconButton
                        size="small"
                        ref={anchorElEmoji}
                        aria-describedby={emojiId}
                        onClick={handleOnEmojiButtonClick}
                        disabled={loading}
                    >
                        <MoodTwoToneIcon />
                    </IconButton>
                    <Popper
                        id={emojiId}
                        open={emojiOpen}
                        anchorEl={anchorElEmoji}
                        disablePortal
                        popperOptions={{
                            modifiers: [
                                {
                                    name: 'offset',
                                    options: {
                                        offset: [-20, 20]
                                    }
                                }
                            ]
                        }}
                    >
                        <ClickAwayListener onClickAway={handleCloseEmoji}>
                            <MainCard elevation={8} content={false}>
                                <Picker onEmojiClick={onEmojiClick} skinTone={SKIN_TONE_NEUTRAL} disableAutoFocus />
                            </MainCard>
                        </ClickAwayListener>
                    </Popper>
                </Grid>

                <Grid item xs={12} sm>
                    <TextField
                        multiline
                        minRows={1}
                        maxRows={4}
                        fullWidth
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        inputRef={inputRef}
                        disabled={loading}
                        InputProps={{
                            startAdornment: attachment ? (
                                <Chip
                                    sx={{ maxWidth: '120px' }}
                                    label={attachment.name}
                                    size="small"
                                    onDelete={loading ? undefined : () => setAttachment(undefined)}
                                />
                            ) : (
                                <Box {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <IconButton size="small" disabled={loading}>
                                        <AttachmentTwoToneIcon />
                                    </IconButton>
                                </Box>
                            ),

                            endAdornment: (
                                <IconButton color="primary" onClick={handleSendMessage} size="small" disabled={!canSendMessage}>
                                    <SendTwoToneIcon />
                                </IconButton>
                            ),

                            onKeyDown: (e) => {
                                if (canSendMessage && e.code === 'Enter' && !e.shiftKey) {
                                    e.stopPropagation();
                                    handleSendMessage();
                                }
                            }
                        }}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

export default ChatInput;
