import { FC, useCallback, useMemo, useState } from 'react';
import { Box, Button, Stack, TextField } from '@mui/material';
import { useFormik } from 'formik';
import { useAppSelector } from '../../../../../../../../../hooks/redux';
import moment from 'moment-timezone';
import NumberFormat from 'react-number-format';
import { IPayment } from '../../../../../../../../../models/IPayment';
import NewPaymentFormStep from './NewPaymentFormStep';
import OtherPaymentMethods from './OtherPaymentMethods';
import validationSchema from './new-payment-schema';
import useNewPaymentOptions from './use-new-payment-options';
import FocusTextField from '../../../../../../../../../ui-component/FocusTextField';

type PaymentFormStep = 'method' | 'method_other' | 'method_coupon' | 'reason' | 'amount';

type NewPaymentFormType = {
    datetime: string;
    method: string;
    reason: Array<string>;
    amount_service: number | null;
    amount_deposit: number | null;
    amount_gratuity: number | null;
    code?: string;
};

const NewPaymentForm: FC<{ onSubmit: (id: string, data: IPayment[]) => void; onCancel: () => void }> = ({ onSubmit, onCancel }) => {
    const { coreMethods, coreReasons, getLabel } = useNewPaymentOptions();
    const { selectedEvent } = useAppSelector((state) => state.calendar);

    const [step, setStep] = useState<PaymentFormStep>('method');

    const formInitialValues = useMemo(
        () => ({
            datetime: moment()
                .tz(selectedEvent?.location.time_zone || 'utc')
                .toString(),
            method: '',
            reason: [],
            amount_service: null,
            amount_deposit: null,
            amount_gratuity: null,
            code: ''
        }),
        [selectedEvent]
    );

    const {
        values,
        setFieldValue,
        setFieldTouched,
        touched,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        resetForm
    } = useFormik<NewPaymentFormType>({
        isInitialValid: true,
        initialValues: formInitialValues,
        enableReinitialize: true,
        validateOnChange: true,
        validateOnBlur: true,
        validationSchema,
        onSubmit: (formData) => {
            const payments: IPayment[] = formData.reason.map((reason) => {
                const amountKey: `amount_${'service' | 'deposit' | 'gratuity'}` = `amount_${reason as 'service' | 'deposit' | 'gratuity'}`;
                return {
                    ...formData,
                    datetime: moment(formData.datetime).tz('UTC').toString(),
                    reason,
                    method: formData.method ? formData.method : undefined,
                    code: formData.code ? formData.code : undefined,
                    amount: formData[amountKey] ?? undefined
                };
            });

            onSubmit(String(selectedEvent?.id), payments);
        }
    });

    const handleMethodSelect = useCallback(
        (method: string) => {
            switch (method) {
                case 'other_methods': {
                    setStep('method_other');
                    break;
                }

                case 'discount_code': {
                    setFieldValue('reason', ['coupon']);
                    setStep('method_coupon');
                    break;
                }

                default: {
                    setFieldValue('method', method);
                    setStep('reason');
                }
            }
        },
        [setFieldValue]
    );

    const handleBackToFormStart = useCallback(() => {
        resetForm();
        setStep('method');
    }, [resetForm]);

    const handleReasonSelect = useCallback(
        (reason: string) => {
            const newVal = values.reason.includes(reason) ? [...values.reason].filter((r) => r !== reason) : [...values.reason, reason];
            setFieldValue('reason', newVal);
        },
        [setFieldValue, values.reason]
    );

    const total = useMemo(() => (values.amount_deposit ?? 0) + (values.amount_service ?? 0) + (values.amount_gratuity ?? 0), [
        values.amount_deposit,
        values.amount_gratuity,
        values.amount_service
    ]);

    return (
        <form id="new-payment-form" onSubmit={handleSubmit} noValidate autoComplete="off">
            {step === 'method' && (
                <NewPaymentFormStep label="Выберите метод оплаты" handlePrev={onCancel}>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                            justifyContent: 'center'
                        }}
                    >
                        {coreMethods.map((method) => (
                            <Button
                                key={method}
                                variant="outlined"
                                sx={{ m: 1 }}
                                onClick={() => {
                                    handleMethodSelect(method);
                                }}
                            >
                                {getLabel(method)}
                            </Button>
                        ))}
                    </Box>
                </NewPaymentFormStep>
            )}

            {step === 'method_other' && (
                <NewPaymentFormStep label="Выберите метод оплаты" handlePrev={handleBackToFormStart}>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                            justifyContent: 'center'
                        }}
                    >
                        <OtherPaymentMethods onSelect={handleMethodSelect} />
                    </Box>
                </NewPaymentFormStep>
            )}

            {step === 'method_coupon' && (
                <NewPaymentFormStep label="Введите код купона" canSubmit={!!values.code} handlePrev={handleBackToFormStart}>
                    <Box>
                        <TextField
                            fullWidth
                            name="code"
                            value={values.code}
                            label="Код купона"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={Boolean(touched.code && errors.code)}
                            helperText={touched.code ? errors.code : undefined}
                        />
                    </Box>
                </NewPaymentFormStep>
            )}

            {step === 'reason' && (
                <NewPaymentFormStep
                    label="Выберите тип платежа"
                    nextBlocked={values.reason.length < 1}
                    handleNext={() => setStep('amount')}
                    handlePrev={handleBackToFormStart}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                            justifyContent: 'center'
                        }}
                    >
                        {coreReasons.map((reason) => (
                            <Button
                                key={reason}
                                variant={values.reason.includes(reason) ? 'contained' : 'outlined'}
                                sx={{ m: 1 }}
                                onClick={() => {
                                    handleReasonSelect(reason);
                                }}
                            >
                                {getLabel(reason)}
                            </Button>
                        ))}
                    </Box>
                </NewPaymentFormStep>
            )}

            {step === 'amount' && (
                <NewPaymentFormStep
                    label="Введите сумму платежа"
                    canSubmit
                    handleNext={() => {
                        handleSubmit();
                    }}
                    handlePrev={() => {
                        setFieldTouched('amount', false);
                        setFieldValue('amount', { service: null, deposit: null, gratuity: null });
                        setStep('reason');
                    }}
                >
                    <Stack spacing={2}>
                        {[...coreReasons]
                            .filter((reason) => values.reason.includes(reason))
                            .map((res) => (
                                <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1} key={res}>
                                    <Box
                                        sx={{
                                            lineHeight: '44px',
                                            alignSelf: 'flex-start'
                                        }}
                                    >{`${getLabel(res)} сумма`}</Box>
                                    <NumberFormat
                                        customInput={FocusTextField}
                                        sx={{
                                            maxWidth: '150px'
                                        }}
                                        error={!!errors[`amount_${res}`] && !!touched[`amount_${res}`]}
                                        helperText={touched[`amount_${res}`] ? errors[`amount_${res}`] : undefined}
                                        id={`amount_${res}`}
                                        name={`amount_${res}`}
                                        value={values[`amount_${res}`] ?? ''}
                                        aria-label={`${res}} сумма`}
                                        suffix=" ₽"
                                        decimalScale={2}
                                        inputProps={{ step: '0.01', min: '0.01' }}
                                        onBlur={handleBlur}
                                        onValueChange={(e) => {
                                            setFieldValue(`amount_${res}`, e.floatValue);
                                        }}
                                    />
                                </Stack>
                            ))}
                        <Stack direction="row" justifyContent="space-between" spacing={1} pt={1}>
                            Сумма введенных платежей:
                            <Box
                                sx={{
                                    maxWidth: '150px'
                                }}
                            >
                                <NumberFormat displayType="text" suffix=" ₽" decimalScale={2} fixedDecimalScale value={total} />
                            </Box>
                        </Stack>
                    </Stack>
                </NewPaymentFormStep>
            )}
        </form>
    );
};

export default NewPaymentForm;
