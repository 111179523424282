import { Autocomplete, AutocompleteRenderInputParams, Box, Popper, TextField, useMediaQuery } from '@mui/material';
import { FC, forwardRef, Fragment, HTMLAttributes, memo, useCallback, useContext, useMemo, useState } from 'react';
import { ICustomer } from '../../models/ICustomer';
import { ICalendarAppointment } from '../../models/IAppointment';
import { SearchOptionType } from './models';
import CustomerOption from './components/CustomerOption';
import AppointmentOption from './components/AppointmentOption';
import useSearch from '../../hooks/useSearch';
import SearchListBox from './components/SearchListbox';
import { useTheme } from '@material-ui/core';
import preferredImageSize from '../../utils/preferred-image-size';
import { getServiceImages, getServiceName } from '../../utils/services';
import { useAppDispatch } from '../../hooks/redux';
import { DialogTypes, openDialog } from '../../store/slices/entityDialogsSlice';
import useAppointmentCloseConfirm from '../../hooks/use-appointment-close-confirm';
import { AbilityContext } from '../../utils/roles/Can';
import { setSelectedCustomerId } from '../../store/slices/calendarFilterSlice';

const SearchFilter: FC<{
    fullWidth?: boolean;
    isSmall?: boolean;
    reverseOptions?: boolean;
    onSeeAllCb?: () => void;
}> = ({ fullWidth, isSmall, reverseOptions, onSeeAllCb }) => {
    const dispatch = useAppDispatch();
    const ability = useContext(AbilityContext);
    const theme = useTheme();
    const matchSm = useMediaQuery(theme.breakpoints.down('sm'));
    const matchMd = useMediaQuery(theme.breakpoints.down('md'));
    const [search, setSearch] = useState('');
    const { customerData, appointmentsData, loading, total } = useSearch({ search });
    const { confirmAppointmentClose } = useAppointmentCloseConfirm();

    const customerOptions = useMemo<SearchOptionType[]>(() => {
        const data: ICustomer[] = customerData?.data ?? [];

        return data.map((customer) => ({
            type: 'Клиенты',
            id: customer.id,
            label: `${customer.firstname} ${customer.lastname}`,
            details: {
                email: customer.email,
                phone: customer.phone,
                upcoming_appointment_id: customer.closest_upcoming_appointment?.id
                    ? parseInt(customer.closest_upcoming_appointment.id, 10)
                    : null
            }
        }));
    }, [customerData]);

    const appointmentOptions = useMemo<SearchOptionType[]>(() => {
        const data: ICalendarAppointment[] = appointmentsData?.data ?? [];

        return data.map((app) => {
            const { firstItemValue, counter } = getServiceName(app.services);
            const images = getServiceImages(app.services);

            return {
                type: 'Записи',
                id: app.id,
                label: counter ? `${firstItemValue} + ${counter} more` : firstItemValue,
                image: images ? preferredImageSize([...images].pop(), 'small') : undefined,
                details: {
                    customerName: `${app.customer.firstname} ${app.customer.lastname}`,
                    status: app.status,
                    date: app.start_at
                }
            };
        });
    }, [appointmentsData]);

    const options = useMemo<SearchOptionType[]>(() => (search ? [...customerOptions, ...appointmentOptions] : []), [
        search,
        customerOptions,
        appointmentOptions
    ]);

    const renderInput = useCallback(
        (params: AutocompleteRenderInputParams) => (
            <TextField
                {...params}
                id="search-filter"
                label="Поиск по имени, телефону или email."
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                autoComplete="off"
                size={matchMd || isSmall ? 'small' : undefined}
            />
        ),

        [isSmall, search, matchMd]
    );

    const handleOptionSelect = useCallback(
        (id: number, type: DialogTypes) => {
            confirmAppointmentClose(() => dispatch(openDialog({ id, type })));
        },
        [confirmAppointmentClose, dispatch]
    );

    const ListBoxComponent = forwardRef<HTMLElement, HTMLAttributes<HTMLElement>>((props, ref) => (
        <SearchListBox count={total} query={search} listProps={props} innerRef={ref} reversed={reverseOptions} onSeeAllCb={onSeeAllCb} />
    ));

    const canCreateAppointment = useMemo(() => ability.can('create', 'appointment'), [ability]);

    const onCreateAppointment = useCallback(
        (id: number) => {
            dispatch(setSelectedCustomerId(id));
            dispatch(openDialog({ type: DialogTypes.Appointment, id: null }));
        },
        [dispatch]
    );

    return (
        <Box sx={{ maxWidth: fullWidth ? undefined : 500, flexGrow: 1, '& .MuiAutocompletePopper': {} }}>
            <Autocomplete
                onOpen={() => {
                    document.body.style.overflow = 'hidden';
                }}
                onClose={() => {
                    document.body.style.overflow = '';
                }}
                renderInput={renderInput}
                options={options}
                loading={loading}
                groupBy={(opt) => opt.type}
                disableClearable
                noOptionsText={search ? 'No results' : 'Type to start search'}
                filterOptions={() => options}
                renderOption={(_props, option) => (
                    <Fragment key={`${option.type}_${option.id}`}>
                        {option.type === 'Клиенты' ? (
                            <CustomerOption
                                option={option}
                                onClick={(id) => handleOptionSelect(id, DialogTypes.Customer)}
                                isMobile={matchSm}
                                onCreateAppointment={canCreateAppointment ? onCreateAppointment : undefined}
                            />
                        ) : (
                            <AppointmentOption
                                option={option}
                                onClick={(id) => handleOptionSelect(id, DialogTypes.Appointment)}
                                isMobile={matchSm}
                            />
                        )}
                    </Fragment>
                )}
                freeSolo
                PopperComponent={(props) => (
                    <Popper
                        {...props}
                        style={{ width: '500px', maxWidth: '100vw' }}
                        placement={reverseOptions ? 'top-start' : props.placement}
                        data-type={reverseOptions ? 'search-autocomplete-bottom' : undefined}
                    />
                )}
                ListboxComponent={ListBoxComponent}
            />
        </Box>
    );
};

export default memo(SearchFilter);
