import { FC } from 'react';
import { Box, IconButton } from '@mui/material';
import CloseIcon from '@material-ui/icons/Close';
import SearchFilter from './SearchFilter';
import { Slide } from '@material-ui/core';

const BottomSearchFilter: FC<{ open: boolean; onClose: () => void }> = ({ open, onClose }) => (
    <Slide in={open} direction="up" mountOnEnter unmountOnExit>
        <Box
            sx={{
                position: 'absolute',
                left: 0,
                bottom: '100%',
                width: '100%',
                height: '48px',
                background: '#fff',
                zIndex: 100,
                display: 'flex',
                alignItems: 'center',
                px: 1,
                pt: 1
            }}
        >
            <Box sx={{ flexGrow: 1 }}>
                <SearchFilter fullWidth isSmall reverseOptions onSeeAllCb={onClose} />
            </Box>
            <IconButton sx={{ flexGrow: 0, flexShrink: 0, color: '#000' }} onClick={onClose}>
                <CloseIcon />
            </IconButton>
        </Box>
    </Slide>
);

export default BottomSearchFilter;
