import { ICustomer, ICustomerPayload } from '../../../models/ICustomer';
import { useMemo } from 'react';
import useAuth from '../../../hooks/useAuth';

const CUSTOMER_DEFAULTS: ICustomerPayload = {
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    birth_date: null,
    note: null,
    address: null,
    employee_owner_id: null
};

const useCustomerFormDefaults = (customer?: ICustomer | null): ICustomerPayload => {
    const { user } = useAuth();
    const userId = user?.employee?.id;
    return useMemo(() => {
        if (customer) {
            const { firstname, lastname, email, phone, birth_date, note, address, employee_owner } = customer;
            return {
                firstname,
                lastname,
                email,
                phone,
                note,
                address,
                birth_date: birth_date ? new Date(birth_date) : null,
                employee_owner_id: employee_owner?.id ?? null
            };
        }

        return { ...CUSTOMER_DEFAULTS, employee_owner_id: userId ?? null };
    }, [customer, userId]);
};

export default useCustomerFormDefaults;
