// material-ui
import Transitions from '../../../../ui-component/extended/Transitions';

// project imports
import { IWidgetUser, UserFormProps } from '../types';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';

// third-party
import { useState, useCallback, useMemo } from 'react';
import { checkFilesErrors, uploadImagesAndSubmit } from '../../../../utils/functions/uploading-images-helpers';
import StepTitle from '../../components/StepTitle';
import { startSubmitting, stopSubmitting } from '../../../../store/slices/SubmittingSlice';
import { SnackBarTypes } from '../../../../store/snackbarReducer';
import useShowSnackbar from '../../../../hooks/useShowSnackbar';
import { setCoupon, setCouponError } from '../../../../store/slices/widgetSlice';
import { formatPhone } from '../../../../utils/phone-helpers';
import appointmentWidgetAPI from '../../../../services/WidgetService';
import calculateCouponDiscountAmount from '../../../../utils/functions/calculate-coupon-discount-amount';
import { getProviderBasedServiceArrayPrice, getServiceIds, isAllServiceExternal } from '../../../../utils/services';
import WidgetUserForm from '../../../../ui-component/widgets/WidgetUserForm';
import useValidateUserData from '../../../../hooks/widgets/use-validate-user-data';

const UserForm = ({
    step,
    userData,
    setUserData,
    handleNext,
    attachments,
    setAttachments,
    attachmentsIdsToDelete,
    setAttachmentsIdsToDelete,
    handleBack,
    addProgress,
    submitted,
    company,
    service,
    submitBooking,
    location_id,
    providerData
}: UserFormProps) => {
    const { coupon, couponError } = useAppSelector((store) => store.widget);
    const dispatch = useAppDispatch();
    const { showSnackbar } = useShowSnackbar();
    const [error, setError] = useState('');

    const processedCoupon = useMemo(() => {
        if (coupon && service) {
            const { couponAmount: amount } = calculateCouponDiscountAmount(getProviderBasedServiceArrayPrice(service, providerData) ?? 0, {
                amount: coupon.amount,
                type: coupon.type
            });
            return { ...coupon, amount };
        }

        return undefined;
    }, [coupon, providerData, service]);

    const isExternal = useMemo(() => isAllServiceExternal(service), [service]);

    const { handleValidateUserData } = useValidateUserData();

    const employee_id = providerData?.id;
    const validateUserData = useCallback(
        (urls: string[], formData: IWidgetUser) => {
            const user = {
                ...formData,
                phone: formatPhone(formData.phone),
                email: formData.email?.toLowerCase(),
                address: isExternal ? formData.address : undefined
            };

            handleValidateUserData(user, {
                location_id,
                employee_id,
                service_ids: service.map(({ id }) => id),
                images: urls,
                coupon_code: coupon?.code,
                company_slug: company.slug
            })
                .then((response) => {
                    setUserData(user);
                    if (response.deposit.required || response.no_show_deposit.required) {
                        addProgress(step);
                        handleNext();
                    } else {
                        submitBooking(null, user, urls);
                    }
                })
                .finally(() => {
                    dispatch(stopSubmitting());
                });
        },
        [
            handleValidateUserData,
            isExternal,
            company.slug,
            location_id,
            service,
            employee_id,
            coupon,
            setUserData,
            dispatch,
            addProgress,
            step,
            handleNext,
            submitBooking
        ]
    );

    const handleCommonError = useCallback(
        (err: any) => {
            showSnackbar({
                message: err.message,
                alertSeverity: SnackBarTypes.Error
            });
            dispatch(stopSubmitting());
        },
        [dispatch, showSnackbar]
    );

    const submitForm = useCallback(
        (formData) => {
            if (checkFilesErrors(attachments) && !error) {
                dispatch(startSubmitting());

                uploadImagesAndSubmit({
                    attachments,
                    submitCb: (urls) => {
                        validateUserData(urls, formData);
                    },
                    updateAttachmentsCb: setAttachments,
                    imagesToDelete: attachmentsIdsToDelete,
                    uploadingErrorCb: handleCommonError,
                    deletingImagesErrorCb: handleCommonError
                });
            }
        },
        [attachments, attachmentsIdsToDelete, dispatch, error, handleCommonError, setAttachments, validateUserData]
    );

    const [validateCoupon, { isLoading: isValidating }] = appointmentWidgetAPI.useValidateBookingCouponMutation();

    const onApply = useCallback(
        (coupon_code: string) => {
            if (service && location_id && company) {
                validateCoupon({
                    coupon_code,
                    location_id,
                    service_ids: getServiceIds(service),
                    company_slug: company.slug
                })
                    .unwrap()
                    .then((res) => {
                        dispatch(setCoupon(res));
                    })
                    .catch((err) => {
                        dispatch(setCouponError(err.message || err.data || JSON.stringify(err)));
                    });
            }
        },
        [company, dispatch, location_id, service, validateCoupon]
    );

    return (
        <Transitions type="fade" in>
            <StepTitle error={false} title="Данные клиента" step={step} handleBack={handleBack} submitted={submitted} />
            <WidgetUserForm
                defaults={userData}
                onSubmitForm={submitForm}
                isExternal={isExternal}
                isAttachmentsEnabled={company.settings.widget.is_attachments_enabled}
                formId={`widget-form-${step}`}
                // Coupon
                isCouponsAllowed={company.is_coupons_allowed}
                coupon={processedCoupon}
                couponError={couponError}
                onApplyCoupon={onApply}
                onDeleteCoupon={() => dispatch(setCoupon())}
                onClearCouponError={() => dispatch(setCouponError(''))}
                isCouponValidating={isValidating}
                // Attachments
                attachments={attachments}
                setAttachments={setAttachments}
                attachmentError={error}
                setAttachmentError={setError}
                attachmentsIdsToDelete={attachmentsIdsToDelete}
                setAttachmentsIdsToDelete={setAttachmentsIdsToDelete}
            />
        </Transitions>
    );
};

export default UserForm;
