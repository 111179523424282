import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';

const phoneUtil = PhoneNumberUtil.getInstance();

const isValidPhone = (phone: string) => {
    try {
        return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
    } catch (err) {
        return false;
    }
};

const formatPhone = (phone: string) => {
    try {
        return phoneUtil.format(phoneUtil.parseAndKeepRawInput(phone), PhoneNumberFormat.E164);
    } catch {
        return phone;
    }
};

const displayPhone = (phone: string) => {
    try {
        const parsedNumber = phoneUtil.parseAndKeepRawInput(phone);
        const nationalNumber = phoneUtil.format(parsedNumber, PhoneNumberFormat.INTERNATIONAL);

        return `${nationalNumber}`;
    } catch {
        return phone;
    }
};

export { isValidPhone, formatPhone, displayPhone };
