import { Grid } from '@material-ui/core';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import StyledBackButton from './StyledBackButton';
import { WidgetSummaryProps } from '../widget-wizard/types';
import WidgetStepTitle from '../../../ui-component/widgets/WidgetStepTitle';

interface StepTitleProps {
    error: boolean;
    title: string;
    step: number;
    handleBack: () => void;
    submitted: WidgetSummaryProps['submitted'];
}

const StepTitle = ({ error, title, step, handleBack, submitted }: StepTitleProps) => (
    <Grid container justifyContent="space-between" mb={1.5}>
        <Grid item>
            <WidgetStepTitle title={title} error={error} />
        </Grid>
        <Grid item sx={{ display: { xs: 'none', sm: 'block' } }}>
            {step > 0 && !submitted && (
                <StyledBackButton sx={{ mt: '5px' }} variant="contained" startIcon={<ArrowBackIos />} onClick={handleBack} id="Prev-Step">
                    Пред. шаг
                </StyledBackButton>
            )}
        </Grid>
    </Grid>
);

export default StepTitle;
