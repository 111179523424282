import { FC, useCallback } from 'react';
import { IAppointment } from '../../../models/IAppointment';
import useSaveAppointment from '../../../views/calendar/appointment-card/appointment-details/hooks/useSaveAppointment';
import useAppointmentFunctions from '../../../views/calendar/components/hooks/useAppointmentFunctions';
import { FormControlLabel, Switch, Box, Grid } from '@mui/material';
import ResendConfirmationButton from './ResendConfirmationButton';
import ResendWaiverButton from './ResendWaiverButton';
import useAuth from '../../../hooks/useAuth';

const AppointmentNotifications: FC<{ appointment: IAppointment; isReadOnly?: boolean }> = ({ appointment, isReadOnly }) => {
    const { user } = useAuth();
    const { saveAppointment } = useSaveAppointment();
    const { formatAppointmentPayload } = useAppointmentFunctions();

    const isReSendBtnDisabled = !user?.currentCompany.settings?.notifications?.enabled;

    const handleToggle = useCallback(
        (e: unknown, v: boolean) => {
            const data = formatAppointmentPayload(appointment);
            data.is_notifications_enabled = v;
            saveAppointment(data, undefined, appointment.id.toString());
        },
        [appointment, formatAppointmentPayload, saveAppointment]
    );

    return (
        <Grid container item xs={12} spacing={2} alignItems="center">
            <Grid item sx={{ flexGrow: 1 }}>
                <Box>
                    <FormControlLabel
                        control={
                            <Switch
                                defaultChecked={Boolean(appointment.is_notifications_enabled)}
                                onChange={handleToggle}
                                disabled={isReadOnly}
                            />
                        }
                        label="Отправлять уведомления"
                        labelPlacement="start"
                        sx={{ ml: 0 }}
                    />
                </Box>
            </Grid>

            {!isReadOnly && (
                <Grid item xs="auto">
                    <ResendConfirmationButton appointmentId={appointment.id} disabled={isReSendBtnDisabled} />
                </Grid>
            )}

            {appointment.has_waiver === 'not_signed' && !isReadOnly && (
                <Grid item xs="auto">
                    <ResendWaiverButton appointment_id={appointment.id ?? 0} />
                </Grid>
            )}
        </Grid>
    );
};

export default AppointmentNotifications;
