import EventNoteOutlined from '@material-ui/icons/EventNoteOutlined';
import LocationOnOutlined from '@material-ui/icons/LocationOnOutlined';
import WorkOutline from '@material-ui/icons/WorkOutline';
import AssignmentIndOutlined from '@material-ui/icons/AssignmentIndOutlined';
import PeopleAltOutlined from '@material-ui/icons/PeopleAltOutlined';
import CouponIcon from '../ui-component/icons/CouponsIcon';
import MeetingRoomOutlined from '@material-ui/icons/MeetingRoomOutlined';
import FeedOutlined from '@material-ui/icons/FeedOutlined';
import DashboardIcon from '@material-ui/icons/Dashboard';
import Settings from '@material-ui/icons/Settings';
import FactCheck from '@material-ui/icons/FactCheck';
import Receipt from '@material-ui/icons/Receipt';
import LocalMallOutlinedIcon from '@material-ui/icons/LocalMallOutlined';
import BuildIcon from '@material-ui/icons/Build';

export const calendarItem = {
    id: 'calendar',
    role_subject: 'appointment',
    title: 'Календарь',
    type: 'item',
    url: '/calendar',
    icon: EventNoteOutlined,
    breadcrumbs: false
};

export const locationItem = {
    id: 'location',
    role_subject: 'location',
    title: 'Локации',
    type: 'item',
    url: '/location',
    icon: LocationOnOutlined,
    breadcrumbs: false
};

export const serviceItem = {
    id: 'service',
    role_subject: 'service',
    title: 'Услуги',
    type: 'item',
    url: '/service',
    icon: WorkOutline,
    breadcrumbs: false
};

export const productItem = {
    id: 'products',
    role_subject: 'product',
    title: 'Продукты',
    type: 'item',
    url: '/products',
    icon: LocalMallOutlinedIcon,
    breadcrumbs: false
};

export const employeeItem = {
    id: 'employee',
    role_subject: 'employee',
    title: 'Персонал',
    type: 'item',
    url: '/employee',
    icon: AssignmentIndOutlined,
    breadcrumbs: false
};

export const customerItem = {
    id: 'customer',
    role_subject: 'customer',
    title: 'Клиенты',
    type: 'item',
    url: '/customer',
    icon: PeopleAltOutlined,
    breadcrumbs: false
};

export const couponsItem = {
    id: 'coupons',
    role_subject: 'company',
    title: 'Купоны',
    type: 'item',
    url: '/coupons',
    icon: CouponIcon,
    breadcrumbs: false
};

export const organizationItem = {
    id: 'organization',
    role_subject: 'company',
    title: 'Компания',
    type: 'item',
    url: '/organization',
    icon: MeetingRoomOutlined,
    breadcrumbs: false
};
export const reportsItem = {
    id: 'reports',
    role_subject: 'company',
    title: 'Отчеты',
    type: 'item',
    url: '/reports',
    icon: FeedOutlined,
    breadcrumbs: false
};
export const insightsItem = {
    id: 'insights',
    role_subject: 'company',
    title: 'Статистика',
    type: 'item',
    url: '/insights',
    icon: DashboardIcon,
    breadcrumbs: false
};
export const settingsItem = {
    id: 'settings',
    role_subject: 'company',
    title: 'Общие',
    type: 'item',
    url: '/settings',
    icon: Settings,
    breadcrumbs: false
};
export const widgetItem = {
    id: 'widget-settings',
    role_subject: 'company',
    title: 'Виджет',
    type: 'item',
    url: '/widget-settings',
    icon: FactCheck,
    breadcrumbs: false
};
export const formBuilderItem = {
    id: 'form-builder',
    role_subject: 'company',
    title: 'Конструктор форм',
    type: 'item',
    url: '/forms',
    icon: Receipt,
    breadcrumbs: false
};

export const siteBuilderItem = {
    id: 'site-builder',
    role_subject: 'company',
    title: 'Веб страница',
    type: 'item',
    url: '/site-builder',
    icon: BuildIcon,
    breadcrumbs: false
};
