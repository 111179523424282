import moment, { Moment } from 'moment-timezone';
import { TimeZoneObject } from '../../ui-component/time-zone-select/types';
import { IAppointment, IAppointmentPayload } from '../../models/IAppointment';

export const default_time_zone = 'UTC';

export const getTimeZones = (showTimezoneOffset?: boolean) => {
    const timeZones = moment.tz.names();
    const zones: TimeZoneObject[] = [];

    timeZones.forEach((zone, index) => {
        const label = `(UTC${moment.tz(zone).format('Z')}) ${zone}`;
        const timeZoneOption: TimeZoneObject = {
            id: index,
            label: showTimezoneOffset ? label : zone,
            name: zone,
            offset: -moment.tz(zone).utcOffset()
        };
        zones.push(timeZoneOption);
    });

    return zones;
};

export const isEventDateValid = (event: IAppointmentPayload | IAppointment, zone: string) =>
    moment.tz(event.start_at, zone) > moment().tz(zone);

export const setDayStart = (date: Moment) => date.set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
