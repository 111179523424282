import { useCallback, useMemo } from 'react';
import { IAppointmentPayload } from '../../../../../models/IAppointment';
import appointmentAPI from '../../../../../services/AppointmentService';
import { SnackBarTypes } from '../../../../../store/snackbarReducer';
import { startSubmitting, stopSubmitting } from '../../../../../store/slices/SubmittingSlice';
import useShowSnackbar from '../../../../../hooks/useShowSnackbar';
import { useAppDispatch } from '../../../../../hooks/redux';

const useSaveAppointment = () => {
    const { showSnackbar } = useShowSnackbar();
    const dispatch = useAppDispatch();
    const [updateAppointment, { isLoading: isSaving }] = appointmentAPI.useUpdateAppointmentMutation();
    const [createAppointment, { isLoading: isCreating }] = appointmentAPI.useCreateAppointmentMutation();

    const saveAppointment = useCallback(
        (
            data: IAppointmentPayload,
            callback?: () => void,
            appointmentId?: string,
            finalCallback?: () => void,
            errorCb?: (e: { data: string; errors: Record<string, string | string[]> }) => void
        ) => {
            dispatch(startSubmitting());

            const save = appointmentId ? updateAppointment({ appointmentId, data }) : createAppointment(data);

            save.unwrap()
                .then(() => {
                    if (callback) callback();
                })
                .catch((e: { data: string; errors: Record<string, string | string[]> }) => {
                    if (errorCb && e.errors) {
                        errorCb(e);
                    }

                    showSnackbar({
                        message: e.data || 'Appointment was not updated',
                        alertSeverity: SnackBarTypes.Error
                    });
                })
                .finally(() => {
                    dispatch(stopSubmitting());
                    if (finalCallback) finalCallback();
                });
        },
        [createAppointment, dispatch, showSnackbar, updateAppointment]
    );

    const isSavingAppointment = useMemo(() => isSaving || isCreating, [isSaving, isCreating]);

    return { saveAppointment, isSavingAppointment };
};

export default useSaveAppointment;
