import * as Yup from 'yup';

const ShiftScheme = Yup.object().shape({
    opened: Yup.boolean().required(),
    startTime: Yup.string()
        .required('Введите время начала.')
        .test({
            name: 'startTime',
            test: (v, ctx) => {
                const endTime = new Date(`2000-10-10T${ctx.parent.endTime}`).valueOf();
                const startTime = new Date(`2000-10-10T${v}`).valueOf();
                return endTime > startTime;
            },
            message: 'Должно быть раньше времени окончания.'
        }),
    endTime: Yup.string().required('Введите время окончания.'),
    shifts: Yup.array().of(Yup.string().trim()).min(1, 'Добавьте хотя бы одну смену')
});

export default ShiftScheme;
