import { useCallback } from 'react';
import { BookingData } from '../../views/scheduling-widget/widget-wizard/types';
import { axiosServices } from '../../utils/axios';
import { SnackBarTypes } from '../../store/snackbarReducer';
import { setCouponError } from '../../store/slices/widgetSlice';
import appointmentAPI from '../../services/AppointmentService';
import { startSubmitting, stopSubmitting } from '../../store/slices/SubmittingSlice';
import useShowSnackbar from '../useShowSnackbar';
import { useAppDispatch } from '../redux';
import useSavedUserData from '../use-saved-user-data';

type SubmitBookingResponse = {
    message: string;
    appointment: { uuid: string; coupon_result?: boolean };
};

const useSubmitBookingWidget = () => {
    const { showSnackbar } = useShowSnackbar();
    const dispatch = useAppDispatch();
    const { saveUserData } = useSavedUserData();

    const onSubmitBookingWidget = useCallback(
        (company_slug: string, bookingData: BookingData, onSuccessCb: (res: SubmitBookingResponse) => void) => {
            dispatch(startSubmitting());
            axiosServices
                .post(`/public/company/${company_slug}/appointment`, bookingData)
                .then((res) => {
                    const { firstname, lastname, email, phone } = bookingData.user;
                    saveUserData({ firstname, lastname, email, phone });
                    showSnackbar({
                        message: 'Запись успешно создана',
                        alertSeverity: SnackBarTypes.Success
                    });
                    if (res.data.coupon_result === false) {
                        dispatch(setCouponError('Купон не был применен'));
                    }
                    dispatch(appointmentAPI.util.invalidateTags(['Appointment']));

                    onSuccessCb(res.data);
                })
                .catch((err) => {
                    if (err.errors && err.errors['customer.email']) {
                        showSnackbar({
                            message: 'Неверный email',
                            alertSeverity: SnackBarTypes.Error
                        });
                    } else if (err.errors && err.errors['customer.phone']) {
                        showSnackbar({
                            message: 'Неверный номер телефона',
                            alertSeverity: SnackBarTypes.Error
                        });
                    } else {
                        showSnackbar({
                            message: err.message,
                            alertSeverity: SnackBarTypes.Error
                        });
                    }
                })
                .finally(() => {
                    dispatch(stopSubmitting());
                });
        },
        [dispatch, saveUserData, showSnackbar]
    );

    return { onSubmitBookingWidget };
};

export default useSubmitBookingWidget;
