import StepTitle from '../../../components/StepTitle';
import { PaymentFormProps } from '../../types';
import Transitions from '../../../../../ui-component/extended/Transitions';
import { Typography } from '@mui/material';
import useDepositFunctions from '../../../hooks/useDepositFunctions';
import WidgetPaymentForm from './WidgetPaymentForm';
import { useMemo } from 'react';

const PaymentForm = ({
    service,
    submitted,
    step,
    handleBack,
    company,
    date,
    submitBooking,
    gateways,
    location,
    user,
    provider,
    timezone,
    attachments
}: PaymentFormProps) => {
    const { servicePrice, salesTaxAmount } = useDepositFunctions();

    const taxesEnabled = location?.sales_tax_settings.enabled;
    const taxesIncluded = location?.sales_tax_settings.included_in_price;

    const subtitle = useMemo(() => {
        const formattedPrice = `${servicePrice.toFixed(2)} ₽`;

        if (taxesEnabled && taxesIncluded) {
            return `Для данной записи требуется оплата депозита в размере ${formattedPrice} (вкл. налог).`;
        }

        if (taxesEnabled && !taxesIncluded && salesTaxAmount) {
            const formattedSalesTax = `${salesTaxAmount.toFixed(2)} ₽`;
            const salesTaxNotice = `(вкл. ${formattedSalesTax} налог)`;

            return `Для данной записи требуется оплата депозита в размере ${formattedPrice} ${salesTaxNotice}.`;
        }

        return `Для данной записи требуется оплата депозита в размере ${formattedPrice}.`;
    }, [salesTaxAmount, servicePrice, taxesEnabled, taxesIncluded]);

    return (
        <Transitions type="fade" in>
            <StepTitle title="Депозит" error={false} step={step} handleBack={handleBack} submitted={submitted} />
            <Typography mb={1}>{subtitle}</Typography>
            <WidgetPaymentForm
                services={service}
                location={location}
                attachments={attachments}
                depositText={company.settings.widget?.deposit_text}
                gateways={gateways}
                date={date}
                provider={provider}
                timezone={timezone}
                user={user}
                submitBooking={submitBooking}
            />
        </Transitions>
    );
};

export default PaymentForm;
