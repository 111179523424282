import { useMemo } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import AttachmentsUpload from '../../file-uploader-preview/AttachmentsUpload';
import { StepContentProps } from '../../../views/scheduling-widget/widget-wizard/types';
import { FormikValues } from 'formik';
import { AppointmentStatuses } from '../../../models/IAppointment';

interface AttachmentsProps {
    values: FormikValues;
    attachments: StepContentProps['attachments'];
    setAttachments: StepContentProps['setAttachments'];
    error: string;
    setError: (data: string) => void;
    attachmentsIdsToDelete?: (number | string)[];
    setAttachmentsIdsToDelete?: (data: (number | string)[]) => void;
    disabled?: boolean;
    isLoading?: boolean;
}

const Attachments = ({
    values,
    attachments,
    setAttachments,
    error,
    setError,
    attachmentsIdsToDelete,
    setAttachmentsIdsToDelete,
    disabled,
    isLoading
}: AttachmentsProps) => {
    const images = values?.images;

    const editDisabled = useMemo(
        () => values.status === AppointmentStatuses.Canceled || values.status === AppointmentStatuses.Completed || disabled,
        [values, disabled]
    );

    return (
        <>
            {(attachments?.length || !editDisabled) && (
                <>
                    <Typography variant="subtitle1" mt={1} mb={1}>
                        Вложения
                    </Typography>
                    {isLoading ? (
                        <Box sx={{ display: 'flex', justifyContent: 'center', pb: 1 }}>
                            <CircularProgress />
                        </Box>
                    ) : (
                        <AttachmentsUpload
                            attachments={attachments}
                            setAttachments={setAttachments}
                            attachmentsIdsToDelete={attachmentsIdsToDelete}
                            setAttachmentsIdsToDelete={setAttachmentsIdsToDelete}
                            error={error}
                            setError={setError}
                            disableFileEdit
                            galleryImages={images ?? []}
                            readonly={editDisabled}
                        />
                    )}
                </>
            )}
        </>
    );
};

export default Attachments;
