import { Link, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import VideoChatIcon from './VideoChatIcon';
import { SnackBarTypes } from '../../store/snackbarReducer';
import useShowSnackbar from '../../hooks/useShowSnackbar';
import { useCallback, useMemo } from 'react';
import useAuth from '../../hooks/useAuth';
import { MeetingProvidersType } from '../../models/ICompany';
import { bindMenu, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import CommonHeaderBtn from '../CommonHeaderBtn';

const buttonSx = { '& > svg': { fill: 'currentColor' }, alignSelf: 'center' };

const CopyVideoLink = () => {
    const { showSnackbar } = useShowSnackbar();
    const { user } = useAuth();

    const popupState = usePopupState({
        variant: 'popover',
        popupId: 'zoom_call_actions'
    });

    const host = window.location.origin;

    const chatLink = useMemo(() => {
        const companySlug = user?.currentCompany.slug;
        const employeeSlug = user?.employee.slug;

        return `${host}/cal/${companySlug}/${employeeSlug}/cbvc`;
    }, [user, host]);

    const handleCopy = useCallback(() => {
        navigator.clipboard.writeText(chatLink).then(() => {
            showSnackbar({ alertSeverity: SnackBarTypes.Success, message: 'Ссылка скопирована в буфер.' });
        });
    }, [chatLink, showSnackbar]);

    const meetingsProvider = user?.currentCompany.settings?.integrations.meetings;
    const ariaLabel = useMemo(() => {
        if (meetingsProvider === MeetingProvidersType.Whereby) {
            return 'Скопировать ссылку на видеозвонок';
        }

        if (meetingsProvider === MeetingProvidersType.Zoom) {
            return 'Меню видеозвонка';
        }

        return undefined;
    }, [meetingsProvider]);

    if (meetingsProvider === MeetingProvidersType.Whereby) {
        return (
            <Tooltip title={<>{ariaLabel}</>}>
                <span>
                    <CommonHeaderBtn disabled={!user} aria-label={ariaLabel} onClick={handleCopy} sx={buttonSx}>
                        <Typography variant="button" fontSize="8px" textAlign="center" component="div">
                            Видео звонок
                        </Typography>
                    </CommonHeaderBtn>
                </span>
            </Tooltip>
        );
    }

    if (meetingsProvider === MeetingProvidersType.Zoom) {
        return (
            <>
                <Tooltip title={<>{ariaLabel}</>}>
                    <span>
                        <CommonHeaderBtn disabled={!user} aria-label={ariaLabel} {...bindTrigger(popupState)} sx={buttonSx}>
                            <VideoChatIcon />
                        </CommonHeaderBtn>
                    </span>
                </Tooltip>
                <Menu {...bindMenu(popupState)} aria-label="Меню видео звонка">
                    <MenuItem
                        component={Link}
                        href={`${host}/profile/cbvc`}
                        target="_blank"
                        onClick={() => {
                            popupState.close();
                        }}
                    >
                        Начать звонок как организатор
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            handleCopy();
                            popupState.close();
                        }}
                    >
                        Скопировать ссылку для участников
                    </MenuItem>
                </Menu>
            </>
        );
    }

    return null;
};

export default CopyVideoLink;
