import { useMemo } from 'react';
import { TimeSlot, WidgetSummaryProps } from './types';
import { Grid, Box, Typography } from '@mui/material';
import { styled, useTheme } from '@material-ui/core/styles';
import moment from 'moment';
import FormattedPhoneNumber from '../../../ui-component/FormattedPhoneNumber';
import Alarm from '@material-ui/icons/Alarm';
import LocationOn from '@material-ui/icons/LocationOn';
import Person from '@material-ui/icons/Person';
import Event from '@material-ui/icons/Event';
import PersonOutlineOutlined from '@material-ui/icons/PersonOutlineOutlined';
import MailOutlined from '@material-ui/icons/MailOutlined';
import Smartphone from '@material-ui/icons/Smartphone';
import NoteOutlined from '@material-ui/icons/NoteOutlined';
import ServiceCost from '../components/ServiceCost';
import ImageTitle from '../components/ImageTitle';
import { isNumber } from 'lodash';
import { useStepContext } from '../steps-context/StepsContext';
import preferredImageSize from '../../../utils/preferred-image-size';
import {
    getProviderBasedServiceArrayPrice,
    getServiceImages,
    getServiceName,
    isAllServiceExternal,
    isAllServicesFree,
    isAllServiceVirtual,
    isServicesAutoAssignEnabled
} from '../../../utils/services';
import { useAppSelector } from '../../../hooks/redux';
import calculateCouponDiscountAmount from '../../../utils/functions/calculate-coupon-discount-amount';

const StyledSummary = styled(Box)(({ theme }) => ({
    borderRadius: '5px',
    overflow: 'hidden',
    color: theme.palette.widget.text,
    backgroundColor: theme.palette.primary.light,

    '& .summary-info, & .user-info': {
        '& .MuiGrid-root.MuiGrid-item': {
            display: 'flex',
            alignItems: 'center',

            '& .MuiTypography-root': {
                fontSize: '16px',

                '@media(max-width: 600px)': {
                    fontSize: '15px'
                }
            },

            '& .MuiSvgIcon-root': {
                width: '22px',
                marginRight: '8px'
            }
        }
    },

    '& .summary-info': {
        background: theme.palette.grey[100],
        borderRadius: '0 0 5px 5px',

        '& .info-row': {
            cursor: 'pointer',
            padding: theme.spacing(0.5, 2),
            transition: 'background-color 0.3s',

            '&:hover': {
                background: theme.palette.grey[300]
            },

            '&:first-of-type': {
                paddingTop: theme.spacing(1)
            },

            '&:last-child': {
                paddingBottom: theme.spacing(1)
            }
        },

        '& .service-name.MuiTypography-root': {
            fontWeight: 700,
            fontSize: '22px !important'
        }
    },

    '& .user-info': {
        padding: theme.spacing(1, 2),
        cursor: 'pointer',
        transition: 'background-color 0.3s',

        '&:hover': {
            backgroundColor: theme.palette.grey[300]
        },

        '& .user-info-title': {
            fontSize: '18px',
            fontWeight: 700,
            margin: theme.spacing(0.5, 0)
        },

        '& .info-row': {
            margin: theme.spacing(0.5, 0),

            '@media(max-width: 600px)': {
                margin: 0
            }
        }
    }
}));

const BookingSummary = ({
    step,
    stepsProgress,
    setActiveStep,
    serviceData,
    locationData,
    providerData,
    dateData,
    userData,
    submitted,
    company,
    timezone
}: WidgetSummaryProps) => {
    const { coupon } = useAppSelector((store) => store.widget);
    const { steps } = useStepContext();
    const theme = useTheme();

    const handleClickStep = (stepIndex?: number) => {
        if (isNumber(stepIndex) && !submitted && (stepsProgress.includes(stepIndex - 1) || stepIndex < step)) {
            setActiveStep(stepIndex);
        }
    };

    const provider = useMemo(() => providerData || null, [providerData]);

    const getProviderName = (slot: TimeSlot | null) => {
        if (slot && step > steps.date.index) {
            return `${slot.employee.user.title}`;
        }
        if (provider) {
            return `${provider.user?.title || 'Любой исполнитель'}`;
        }
        return 'Выберите исполнителя';
    };

    const location = useMemo(() => locationData || null, [locationData]);

    const summaryImageUrl = useMemo(() => {
        const images = getServiceImages(serviceData) ?? [];
        const src = images?.[images.length - 1] || company?.logo_rectangular || company?.logo;
        return preferredImageSize(src, 'medium');
    }, [company, serviceData]);

    const dateText = useMemo(() => {
        if (!dateData) return 'Выберите дату и время';

        return `${moment(dateData.start_at).tz(timezone).format('MMMM Do YYYY, HH:mm')} (${timezone})`;
    }, [dateData, timezone]);

    const taxesEnabled = Boolean(locationData?.sales_tax_settings.enabled) && Boolean(locationData?.sales_tax_settings.use_for_services);
    const salesTaxRate = locationData?.sales_tax_rate ?? 0;

    const servicePrice = useMemo(() => {
        const basePrice = getProviderBasedServiceArrayPrice(serviceData, providerData);
        const { priceWithDiscount } = calculateCouponDiscountAmount(basePrice, coupon);
        const taxAmount = priceWithDiscount * (salesTaxRate / 100);

        return { value: priceWithDiscount, tax_amount: taxAmount };
    }, [serviceData, providerData, coupon, salesTaxRate]);

    const taxNotice = useMemo(() => {
        if (!!serviceData.length && providerData && !isAllServicesFree(serviceData) && taxesEnabled) {
            if (!servicePrice.tax_amount) {
                return '(вкл. налог)';
            }

            const taxAmountFormatted = `${servicePrice.tax_amount.toFixed(2)} ₽`;
            return `+ ${taxAmountFormatted} налог`;
        }

        return null;
    }, [serviceData, providerData, taxesEnabled, servicePrice.tax_amount]);

    return (
        <>
            <StyledSummary sx={{ display: { xs: step === steps.final.index ? 'inherit' : 'none', sm: 'block' } }}>
                <ImageTitle title="Данные записи" imageUrl={summaryImageUrl} />
                <Grid container className="summary-info" id="summary-info">
                    <Grid
                        item
                        xs={12}
                        container
                        justifyContent="space-between"
                        onClick={() => handleClickStep(steps.service.index)}
                        className="info-row"
                        sx={{ pb: 1 }}
                    >
                        <Grid item xs={12}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                <Typography id="service-name" className="service-name">
                                    {serviceData.length ? getServiceName(serviceData).fullValue : 'Выберите услугу'}
                                </Typography>

                                {serviceData.length ? (
                                    <Box
                                        sx={{
                                            height: '30px',
                                            alignSelf: 'flex-start',
                                            pl: 2,
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <ServiceCost
                                            service={{
                                                is_free: isAllServicesFree(serviceData),
                                                price: servicePrice.value
                                            }}
                                        />
                                    </Box>
                                ) : null}
                            </Box>
                        </Grid>
                        {taxNotice && servicePrice.value > 0 ? (
                            <Grid
                                item
                                xs={12}
                                sx={{
                                    justifyContent: 'flex-end',
                                    color: theme.palette.widget.green,
                                    fontStyle: 'italic'
                                }}
                            >
                                {taxNotice}
                            </Grid>
                        ) : null}
                        {!!dateData?.duration && (
                            <Grid item xs={12} onClick={() => handleClickStep(steps.service.index)}>
                                <Alarm />
                                <Typography id="service-duration">{`${dateData.duration}мин`}</Typography>
                            </Grid>
                        )}
                    </Grid>
                    {!isAllServiceExternal(serviceData) && !isAllServiceVirtual(serviceData) && (
                        <Grid item xs={12} onClick={() => handleClickStep(steps.location.index)} className="info-row">
                            <LocationOn />
                            {location ? (
                                <Typography id="summary-location-data">
                                    {location.name}
                                    <br />
                                    {location.address.address}
                                </Typography>
                            ) : (
                                <Typography id="summary-location-data">Выберите локацию</Typography>
                            )}
                        </Grid>
                    )}
                    {!isServicesAutoAssignEnabled(serviceData) ? (
                        <Grid item xs={12} onClick={() => handleClickStep(steps.provider?.index)} className="info-row">
                            <Person />
                            <Typography id="summary-provider-name">{getProviderName(dateData)}</Typography>
                        </Grid>
                    ) : null}
                    <Grid item xs={12} onClick={() => handleClickStep(steps.date.index)} className="info-row">
                        <Event />
                        <Typography id="summary-appointment-date">{dateText}</Typography>
                    </Grid>
                </Grid>
                {userData && (
                    <Grid container className="user-info" id="user-info" onClick={() => handleClickStep(steps.user.index)}>
                        <Typography className="user-info-title">Запись для</Typography>
                        <Grid item xs={12} className="info-row">
                            <PersonOutlineOutlined />
                            <Typography>{userData ? `${userData.firstname} ${userData.lastname}` : 'Ваше имя'}</Typography>
                        </Grid>
                        {userData?.email && (
                            <Grid item xs={12} className="info-row">
                                <MailOutlined />
                                <Typography>{userData.email}</Typography>
                            </Grid>
                        )}
                        {userData?.phone && (
                            <Grid item xs={12} className="info-row">
                                <Smartphone />
                                <Typography>
                                    <FormattedPhoneNumber phone={userData.phone} />
                                </Typography>
                            </Grid>
                        )}
                        {isAllServiceExternal(serviceData) && userData.address?.address ? (
                            <Grid item xs={12} className="info-row">
                                <LocationOn />
                                <Typography>{userData.address.address}</Typography>
                            </Grid>
                        ) : null}
                        {userData?.note?.trim() && (
                            <Grid item xs={12} className="info-row">
                                <NoteOutlined />
                                <Typography>{`Доп. инфо: ${userData.note}`}</Typography>
                            </Grid>
                        )}
                    </Grid>
                )}
            </StyledSummary>
        </>
    );
};

export default BookingSummary;
