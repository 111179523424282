import { FC, useCallback } from 'react';
import SquareStyledButton from './SquareStyledBtn';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import appointmentAPI from '../../../services/AppointmentService';
import { SnackBarTypes } from '../../../store/snackbarReducer';
import useShowSnackbar from '../../../hooks/useShowSnackbar';
import { useAppDispatch } from '../../../hooks/redux';
import { openConfirmPopup } from '../../../store/confirmPopupSlice';

const ResendWaiverButton: FC<{ appointment_id: number; disabled?: boolean }> = ({ appointment_id, disabled = false }) => {
    const [resend, { isLoading }] = appointmentAPI.useResendWaiverConfirmationMutation();
    const { showSnackbar } = useShowSnackbar();
    const dispatch = useAppDispatch();

    const handleResend = useCallback(() => {
        resend(appointment_id)
            .unwrap()
            .then(() => {
                showSnackbar({
                    message: 'Form confirmation email sent successfully.',
                    alertSeverity: SnackBarTypes.Success
                });
            })
            .catch((err) => {
                showSnackbar({
                    message: err.data || 'Error occurred, confirmation email was not sent.',
                    alertSeverity: SnackBarTypes.Error
                });
            });
    }, [appointment_id, resend, showSnackbar]);

    const handleClick = useCallback(() => {
        dispatch(
            openConfirmPopup({
                onConfirm: handleResend,
                confirmText: `Resend`,
                text: `Are you sure you want to resend form confirmation?`
            })
        );
    }, [dispatch, handleResend]);

    return (
        <SquareStyledButton
            onClick={handleClick}
            startIcon={<AssignmentOutlinedIcon />}
            variant="outlined"
            color="primary"
            disabled={disabled || isLoading}
        />
    );
};

export default ResendWaiverButton;
