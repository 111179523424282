import { Grid } from '@mui/material';
import TwoColumnsSwitch from '../../ui-component/TwoColumnsSwitch';
import InfoTooltip from '../../ui-component/InfoTooltip';
import { FC } from 'react';
import LabelWithInfo from '../../ui-component/LabelWithInfo';
import OptimizedSchedule from '../../ui-component/optimized-text-fields/schedule/OptimizedSchedule';
import { ISchedule } from '../../models/ILocation';
import { FormikErrors } from 'formik';
import StaffShifts from './StaffShifts';
import { EmployeeSubFormValueType } from './use-employee-form-schema';

interface IEmployeeLocationSubformProps {
    values: EmployeeSubFormValueType;
    valuePrefix: string;
    setFieldValue: (field: string, value: any) => void;
    errors?: FormikErrors<EmployeeSubFormValueType>;
    planName: string | null;
}
const EmployeeLocationSubform: FC<IEmployeeLocationSubformProps> = ({ values, valuePrefix, setFieldValue, errors, planName }) => (
    <>
        {planName !== 'single user' && (
            <TwoColumnsSwitch
                value={values.use_location_schedule}
                fieldName={`${valuePrefix}.use_location_schedule`}
                setFieldValue={setFieldValue}
                label="Использовать расписание локации"
                labelDecoration={
                    <InfoTooltip text="Если включено, расписание данного сотрудника полностью соответствует расписанию локации, в которой он работает. Отключите для настройки индивидуального расписания, отличного от расписания локации." />
                }
            />
        )}
        {!values.use_location_schedule && (
            <>
                <Grid item xs={12} sm={3} lg={4} sx={{ pt: { xs: 2, sm: '0 !important' } }}>
                    <LabelWithInfo label="Расписание" infoText="Выберите дни и время, когда данный сотрудник доступен для брони." />
                </Grid>
                <Grid item xs={12} sm={9} lg={8} sx={{ overflow: 'auto' }}>
                    <OptimizedSchedule
                        schedules={values.schedule}
                        onChange={(schedules: ISchedule[]) => {
                            setFieldValue(`${valuePrefix}.schedule`, schedules);
                        }}
                        errors={errors}
                    />
                </Grid>
            </>
        )}
        <StaffShifts
            setFieldValue={setFieldValue}
            is_shifts_enabled={values.is_shifts_enabled}
            checkboxFieldName={`${valuePrefix}.is_shifts_enabled`}
            shifts={values.shifts}
            setShifts={(shifts) => {
                setFieldValue(`${valuePrefix}.shifts`, shifts);
            }}
            error={typeof errors?.shifts === 'string' ? errors.shifts : null}
            errors={errors}
            label="Индивидуальные смены"
            tooltip="Индивидуальные смены позволяют добавить расписание для конкретных дат, когда доступность сотрудника отличается от обычной. Например, на время отпуска или больничного."
        />
    </>
);

export default EmployeeLocationSubform;
