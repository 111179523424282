import { TabsProps } from '../types';
import { Box } from '@mui/material';

export function a11yProps(index: number, prefix: string) {
    return {
        id: `${prefix}-tab-${index}`,
        'aria-controls': `${prefix}-tabpanel-${index}`
    };
}

function TabPanel({ children, value, index, prefix, ...other }: TabsProps & { prefix: string }) {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`${prefix}-tabpanel-${index}`}
            aria-labelledby={`${prefix}-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
        </div>
    );
}

export default TabPanel;
