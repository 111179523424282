import { useCallback } from 'react';
import { IWidgetUser } from '../views/scheduling-widget/widget-wizard/types';

const useSavedUserData = () => {
    const { hostname } = window.location;

    const saveUserData = useCallback(
        (data: Pick<IWidgetUser, 'firstname' | 'lastname' | 'email' | 'phone'>) => {
            const dataString = JSON.stringify(data);
            document.cookie = `_customer_form_data=${dataString};max-age=${60 * 60 * 24 * 90};domain=${hostname}`;
        },
        [hostname]
    );

    const loadUserData = useCallback((): Partial<IWidgetUser> | null => {
        try {
            const cookie = document.cookie
                .split('; ')
                .find((row) => row.startsWith('_customer_form_data'))
                ?.split('=')[1];

            if (cookie) {
                return JSON.parse(cookie);
            }

            return null;
        } catch (err) {
            console.warn(err);
            return null;
        }
    }, []);

    return { saveUserData, loadUserData };
};

export default useSavedUserData;
