// material-ui
import { Button, Grid } from '@material-ui/core';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import { useNavigate, useParams } from 'react-router';
import React, { useContext, useEffect } from 'react';

// assets
import ChevronLeftOutlinedIcon from '@material-ui/icons/ChevronLeftOutlined';
import serviceAPI from 'services/ServiceService';
import { IService } from 'models/IService';
import ServiceForm from './ServiceForm';
import { min_service_reschedule_interval } from '../../store/constant';
import { AbilityContext } from '../../utils/roles/Can';
import useMobileCreateButton from '../../hooks/useMobileCreateButton';
import useInvalidateServices from './use-invalidate-services';
import useShowSnackbar from '../../hooks/useShowSnackbar';
import { SnackBarTypes } from '../../store/snackbarReducer';

const ServiceEdit: React.FC = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const invalidate = useInvalidateServices();
    const ability = useContext(AbilityContext);

    useMobileCreateButton({
        action: () => navigate('/service/create'),
        condition: ability.can('create', 'service')
    });

    // @ts-ignore
    const { data, isFetching } = serviceAPI.useGetServiceQuery(id, {
        refetchOnMountOrArgChange: true
    });
    const [updateService] = serviceAPI.useUpdateServiceMutation();

    const { showSnackbar } = useShowSnackbar();

    const handleUpdate = (service: IService) => {
        if (!service.rescheduling_interval) service.rescheduling_interval = min_service_reschedule_interval;

        updateService(service)
            .unwrap()
            .then(() => {
                showSnackbar({
                    message: 'Услуга обновлена',
                    alertSeverity: SnackBarTypes.Success
                });
                navigate('/service', { replace: true });
                invalidate();
            })
            .catch((e) => {
                showSnackbar({
                    message: e.data || 'Ошибка: услуга не обновлена',
                    alertSeverity: SnackBarTypes.Error
                });
            });
    };

    useEffect(() => {
        if (!isFetching && !data) {
            navigate('/service', { replace: true });
        }
    }, [data, isFetching, navigate]);

    return (
        <>
            {!isFetching && data && (
                <Grid>
                    <MainCard
                        title={data.name}
                        secondary={
                            <Button size="small" disableElevation onClick={() => navigate(-1)}>
                                <ChevronLeftOutlinedIcon />
                                Назад
                            </Button>
                        }
                        contentSX={{ p: { xs: 1.5, sm: 3 } }}
                    >
                        <ServiceForm service={data} update={handleUpdate} />
                    </MainCard>
                </Grid>
            )}
        </>
    );
};

export default ServiceEdit;
