import waiverAPI from '../../services/WaiverService';
import { useState, useMemo, useContext } from 'react';
import MainCard from 'ui-component/cards/MainCard';
import { CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@material-ui/core';
import { IWaiver } from '../../models/ICompany';
import Can, { AbilityContext } from '../../utils/roles/Can';
import { openConfirmPopup } from '../../store/confirmPopupSlice';
import { useAppDispatch } from '../../hooks/redux';
import DeleteIconButton from '../../ui-component/DeleteIconButton';
import EditIconButton from '../../ui-component/EditIconButton';
import useShowSnackbar from '../../hooks/useShowSnackbar';
import { SnackBarTypes } from '../../store/snackbarReducer';
import AppPagePagination from '../../ui-component/PagePagination';
import AddIconFab from '../../ui-component/AddIconFab';
import GroupOfAvatars, { MoveToProps } from '../../ui-component/GroupOfAvatars';
import { getAvatarData } from '../../ui-component/AvatarData';
import { useNavigate } from 'react-router';

const WaiverList = () => {
    const dispatch = useAppDispatch();
    const { showSnackbar } = useShowSnackbar();
    const ability = useContext(AbilityContext);
    const navigate = useNavigate();

    const [page, setPage] = useState<number>(1);
    const [perPage, setPerPage] = useState<number>(10);

    const { data, isLoading } = waiverAPI.useFetchAllWaiversQuery({
        per_page: perPage,
        page,
        sort: 'id',
        order: 'desc'
    });

    const waivers = useMemo(() => (!isLoading ? data?.data || [] : []), [data, isLoading]);

    const [deleteWaiver] = waiverAPI.useDeleteWaiverMutation();

    const removeWaiver = (waiver: IWaiver) => {
        deleteWaiver(waiver)
            .unwrap()
            .then(() => {
                showSnackbar({
                    message: 'Form deleted successfully.',
                    alertSeverity: SnackBarTypes.Success
                });
            })
            .catch(() => {
                showSnackbar({
                    message: 'Error occurred, form was not deleted, please contact administrator.',
                    alertSeverity: SnackBarTypes.Error
                });
            });
    };

    const handleClickDelete = (waiver: IWaiver) => {
        dispatch(
            openConfirmPopup({
                onConfirm: () => removeWaiver(waiver),
                confirmText: `Delete`,
                text: `Are you sure you want to delete ${waiver.title} ?`
            })
        );
    };

    const handleClosePagination = (limit?: number) => {
        if (limit) {
            setPerPage(limit);
            setPage(1);
        }
    };

    const moveTo = ({ path, id }: MoveToProps) => {
        navigate(`/${path}/${id}`);
    };

    return (
        <MainCard
            title={<Typography sx={{ fontSize: '18px' }}>Forms</Typography>}
            secondary={
                <Can I="update" a="company">
                    <AddIconFab tooltip="Add form" to="/forms/create" />
                </Can>
            }
            content={false}
        >
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ pl: 3 }}>Title</TableCell>
                            <TableCell>Services</TableCell>
                            <TableCell align="center" sx={{ width: '120px' }}>
                                Actions
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {waivers.map((row: IWaiver, index) => (
                            <TableRow key={`waiver-${index}`}>
                                <TableCell sx={{ pl: 3 }}>{row.title}</TableCell>
                                <TableCell>
                                    {row.services && row.services.length > 0 && (
                                        <GroupOfAvatars
                                            maxCount={4}
                                            data={getAvatarData({
                                                data: row.services,
                                                path: 'service',
                                                moveTo,
                                                isClickable: ability.can('update', 'company')
                                            })}
                                            isClickable={ability.can('update', 'company')}
                                        />
                                    )}
                                </TableCell>
                                <TableCell align="center" sx={{ width: '120px' }}>
                                    <Can I="update" a="company">
                                        <Tooltip placement="top" title="Edit form">
                                            <EditIconButton to={`/forms/${row.id}`} />
                                        </Tooltip>
                                        <Tooltip placement="top" title="Delete form">
                                            <DeleteIconButton onClick={() => handleClickDelete(row)} />
                                        </Tooltip>
                                    </Can>
                                </TableCell>
                            </TableRow>
                        ))}
                        {isLoading && (
                            <TableRow>
                                <TableCell colSpan={3} align="center">
                                    <CircularProgress />
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <AppPagePagination page={page} data={data} perPage={perPage} setPageSize={handleClosePagination} setPage={setPage} />
        </MainCard>
    );
};

export default WaiverList;
