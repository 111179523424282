// material-ui
import { Box, Tooltip, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';

// project import
import useAuth from 'hooks/useAuth';
import CommonHeaderBtn from '../../../../ui-component/CommonHeaderBtn';

const WidgetLink = () => {
    const { user } = useAuth();

    return (
        <Tooltip title="Онлайн запись" placement="top" arrow>
            <Box component="span">
                <CommonHeaderBtn
                    // @ts-ignore
                    component={Link}
                    to={`/cal/${user?.currentCompany.slug}`}
                    target="_blank"
                    size="100px"
                >
                    <Typography variant="button" fontSize="8px" textAlign="center" component="div">
                        Ссылка Клиенту
                    </Typography>
                </CommonHeaderBtn>
            </Box>
        </Tooltip>
    );
};

export default WidgetLink;
