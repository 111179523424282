import { FC, useCallback, useState, ChangeEvent, useEffect } from 'react';
import { FormikProps } from 'formik';
import { Box, Tab, Tabs } from '@mui/material';
import TextField from '@mui/material/TextField';
import ErrorIcon from '@material-ui/icons/Error';

type AppointmentNotesProps = {
    formikInstance: Pick<FormikProps<any>, 'values' | 'handleBlur' | 'touched' | 'errors' | 'setFieldTouched' | 'setFieldValue'>;
    isDisabled?: boolean;
    showCustomerNotes?: boolean;
};

const AppointmentNotes: FC<AppointmentNotesProps> = ({ formikInstance, isDisabled, showCustomerNotes }) => {
    const [mode, setMode] = useState<'notes' | 'customer_notes'>('notes');
    const { values, setFieldTouched, setFieldValue, handleBlur, touched, errors } = formikInstance;

    const handleChangeCustomerNote = useCallback(
        (e: ChangeEvent<HTMLTextAreaElement>) => {
            setFieldTouched('note');
            setFieldValue('note', e.target.value);
        },
        [setFieldValue, setFieldTouched]
    );

    const handleChangePrivateNote = useCallback(
        (e: ChangeEvent<HTMLTextAreaElement>) => {
            setFieldTouched('private_note');
            setFieldValue('private_note', e.target.value);
        },
        [setFieldValue, setFieldTouched]
    );

    useEffect(() => {
        if (!showCustomerNotes && mode === 'customer_notes') {
            setMode('notes');
        }
    }, [mode, showCustomerNotes]);

    return (
        <Box>
            {showCustomerNotes ? (
                <Tabs value={mode} sx={{ mb: 2 }}>
                    <Tab value="notes" onClick={() => setMode('notes')} label="Заметки" />
                    <Tab
                        value="customer_notes"
                        onClick={() => setMode('customer_notes')}
                        label={
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                <ErrorIcon color="error" /> Инфо от клиента
                            </Box>
                        }
                    />
                </Tabs>
            ) : null}

            {mode === 'notes' || !showCustomerNotes ? (
                <TextField
                    fullWidth
                    id="private_note"
                    name="private_note"
                    rows={4}
                    multiline
                    label="Заметки"
                    value={values?.private_note || ''}
                    onChange={handleChangePrivateNote}
                    onBlur={handleBlur}
                    error={Boolean(touched?.private_note && errors?.private_note)}
                    helperText={errors?.private_note}
                    disabled={isDisabled}
                />
            ) : null}

            {mode === 'customer_notes' ? (
                <TextField
                    fullWidth
                    id="note"
                    name="note"
                    rows={4}
                    multiline
                    label="Инфо от клиента"
                    value={values?.note || ''}
                    onChange={handleChangeCustomerNote}
                    onBlur={handleBlur}
                    error={Boolean(touched?.note && errors?.note)}
                    helperText={errors?.note}
                    disabled={isDisabled}
                />
            ) : null}
        </Box>
    );
};

export default AppointmentNotes;
