import AddIcon from '@material-ui/icons/Add';
import { IconButton, IconButtonProps } from '@mui/material';
import { useTheme } from '@material-ui/core';

const AddIconButton = (props: IconButtonProps) => {
    const theme = useTheme();
    const { sx, ...rest } = props;

    return (
        <IconButton
            {...rest}
            size="large"
            sx={{
                backgroundColor: theme.palette.primary?.main,
                borderRadius: '8px',
                width: '44px',
                height: '44px',
                minWidth: '44px',
                padding: '7px 11px',
                '&:hover': {
                    backgroundColor: `${theme.palette.primary?.main}80`
                },
                '&.Mui-disabled': { backgroundColor: `${theme.palette.primary?.main}80` },
                ...sx
            }}
        >
            <AddIcon sx={{ color: 'white', width: '100%', height: '100%' }} />
        </IconButton>
    );
};

export default AddIconButton;
