import { useCallback, useMemo, useState } from 'react';

// material-ui
import { makeStyles } from '@material-ui/styles';
import { Theme, useTheme } from '@material-ui/core/styles';
import { Typography, Box } from '@mui/material';

// assets
import { INotification } from 'models/INotification';
import moment from 'moment';
import EllipsisTypography from '../../../../ui-component/optimized-text-fields/EllipsisTypography';
import TimeZoneView from '../../../../ui-component/TimeZoneView';
import useNotificationItem from '../../../../hooks/useNotificationItem';

// style constant
const useStyles = makeStyles((theme: Theme) => ({
    itemAction: {
        cursor: 'pointer',
        padding: '10px 20px',
        '&:hover': {
            background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary[200]
        }
    }
}));

// ==============================|| NOTIFICATION LIST ITEM ||============================== //

type NotificationItemProps = {
    notification: INotification;
    setOpen: (arg: boolean) => void;
};

const NotificationItem = ({ notification, setOpen }: NotificationItemProps) => {
    const theme = useTheme();
    const classes = useStyles();
    const [isClicked, setIsClicked] = useState(false);
    const { isMessage, customerName, notificationData, handleClickNotification } = useNotificationItem(notification);

    const handleClickAppointment = useCallback(async () => {
        if (!isClicked) {
            setIsClicked(true);
            handleClickNotification().finally(() => {
                setOpen(false);
                setIsClicked(false);
            });
        }
    }, [handleClickNotification, isClicked, setOpen]);

    const notification_time_zone = useMemo(() => notification.data.location.time_zone || notification.data.location.time_zone_name, [
        notification
    ]);

    return (
        <Box
            className={classes.itemAction}
            onClick={handleClickAppointment}
            sx={{ background: notification.read_at ? '#fff' : theme.palette.primary.light }}
        >
            <Typography variant="subtitle1">{notificationData.title}</Typography>
            <Box>
                <EllipsisTypography variant="subtitle2" text={customerName} ml={0} sx={{ maxWidth: '100%' }} />
                {!isMessage ? (
                    <Typography variant="subtitle2">
                        {notification?.data?.service?.name}{' '}
                        {notification_time_zone
                            ? moment(notification?.data?.start_at).tz(notification_time_zone).format('D/M/YY [в] HH:mm')
                            : moment(notification?.data?.start_at).format('D/M/YY [в] HH:mm')}
                        {notification_time_zone ? (
                            <TimeZoneView
                                time_zone={notification_time_zone}
                                variant="subtitle2"
                                sx={{ whiteSpace: 'normal' }}
                                component="span"
                                showOffset={false}
                            />
                        ) : null}
                    </Typography>
                ) : null}
            </Box>
        </Box>
    );
};

export default NotificationItem;
