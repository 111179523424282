import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ruLocale from 'date-fns/locale/ru';

class AdapterDateFnsWithLocale extends AdapterDateFns {
    constructor() {
        super({ locale: ruLocale });
    }
}

export default AdapterDateFnsWithLocale;
