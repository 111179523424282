import { useCallback, useMemo } from 'react';
import { UserRole } from '../models/IEmployee';
import useAuth from './useAuth';

const rolesCanEditCustomerOwner = [UserRole.Admin, UserRole.Manager, UserRole.Owner];
const rolesCanEditCustomer = [...rolesCanEditCustomerOwner, UserRole.FrontDesk];

const useCanSeeCustomerDetails = () => {
    const { user } = useAuth();
    const appointmentSettings = user?.currentCompany.settings?.appointments;
    const employeeRole = user?.employee?.role?.name;
    const employeeId = user?.employee?.id;

    const canEditCustomerDetails = useCallback(
        (ownerId?: number | null) => {
            const privacyPolicyEnabled: boolean = appointmentSettings ? appointmentSettings.use_contact_privacy : true;

            // Show all details when contacts privacy is disabled or customer don't have any owner id...
            if (!privacyPolicyEnabled || !ownerId) {
                return true;
            }

            // ... or if user role allows to edit customer.
            if (employeeRole && rolesCanEditCustomer.includes(employeeRole)) {
                return true;
            }

            // Otherwise, show all details only if user employee id match with owner id
            return !!employeeId && employeeId === ownerId;
        },
        [appointmentSettings, employeeId, employeeRole]
    );

    const canEditCustomerOwner = useMemo(() => !!employeeRole && rolesCanEditCustomerOwner.includes(employeeRole), [employeeRole]);

    return { canEditCustomerDetails, canEditCustomerOwner };
};

export default useCanSeeCustomerDetails;
