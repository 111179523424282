import { ICompany } from '../../models/ICompany';
import companyAPI from '../../services/CompanyService';
import useShowSnackbar from '../../hooks/useShowSnackbar';
import { useNavigate } from 'react-router';
import { SnackBarTypes } from '../../store/snackbarReducer';
import { Box, CardActions, CardContent, Divider, FormControl, Grid, InputAdornment } from '@material-ui/core';
import TwoColumnsSwitch from '../../ui-component/TwoColumnsSwitch';
import { LoadingButton } from '@mui/lab';
import SaveOutlined from '@material-ui/icons/SaveOutlined';
import MainCard from '../../ui-component/cards/MainCard';
import WidgetEmbedCode from './WidgetEmbedCode';
import OptimizedTextField from '../../ui-component/optimized-text-fields/OptimizedTextField';
import WidgetStyleSettings from './WidgetStyleSettings';
import WidgetLinkBuilder from './WidgetLinkBuilder';
import LabelWithInfo from '../../ui-component/LabelWithInfo';
import InfoTooltip from '../../ui-component/InfoTooltip';
import TextEditor from 'ui-component/TextEditor';
import TextField from '@mui/material/TextField';
import useGenerateLink from './useGenerateLink';
import { useMemo } from 'react';
import * as Yup from 'yup';
import useExtendedFormik from '../../hooks/useExtendedFormik';

interface WidgetSettingsFormProps {
    company: ICompany;
}

const WidgetSettingsForm = ({ company }: WidgetSettingsFormProps) => {
    const [updateCompanySettings, { isLoading }] = companyAPI.useUpdateCompanySettingsMutation();
    const { generateLink } = useGenerateLink(company);

    const { showSnackbar } = useShowSnackbar();
    const navigate = useNavigate();

    const { handleSubmit, values, setFieldValue, handleChange, handleBlur, touched, errors } = useExtendedFormik({
        enableReinitialize: true,
        validateOnChange: true,
        initialValues: company.settings.widget,
        onSubmit: (formData) => {
            updateCompanySettings({ settings: { ...company.settings, widget: formData }, companyId: company.id })
                .unwrap()
                .then(() => {
                    showSnackbar({
                        message: 'Настройки успешно сохранены.',
                        alertSeverity: SnackBarTypes.Success
                    });
                    navigate('/');
                })
                .catch((e) => {
                    showSnackbar({
                        message: e.data || 'Ошибка: настройки не были сохранены.',
                        alertSeverity: SnackBarTypes.Error
                    });
                });
        },
        validationSchema: Yup.object().shape({
            link_builder: Yup.array().of(
                Yup.object().shape({
                    selector: Yup.string()
                        .trim()
                        .nullable()
                        .notRequired()
                        .test('selector', 'Укажите корректный CSS класс или ID (включая "." или "#").', (val) => {
                            if (val) {
                                return /^(\.|#)[^\s.#]+$/.test(val);
                            }
                            return true;
                        })
                })
            )
        })
    });

    const triggers = useMemo<Record<string, string> | undefined>(() => {
        if (values.link_builder) {
            const result: Record<string, string> = {};
            values.link_builder.forEach((v) => {
                if (v.selector) {
                    result[v.selector] = generateLink(v);
                }
            }, []);

            return Object.keys(result).length ? result : undefined;
        }
        return undefined;
    }, [generateLink, values.link_builder]);

    return (
        <MainCard title="Настройки виджета" content={false}>
            <form onSubmit={handleSubmit} noValidate>
                <CardContent>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={12} sm={3} lg={4}>
                                    <LabelWithInfo
                                        label="Код встраиваемого виджета"
                                        infoText='Используйте этот код, чтобы добавить виджет к себе на сайт. Скопируйте его в самый конец html тэга <body>. Назначьте класс "cb-widget-btn" элементу, которым хотите активировать виджет.'
                                    />
                                </Grid>
                                <Grid item xs={12} sm={9} lg={6}>
                                    <WidgetEmbedCode slug={company.slug} triggers={triggers} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} container>
                            <Grid item xs={12} sm={3} lg={4}>
                                Дополнительные CSS стили
                            </Grid>
                            <Grid item xs={12} sm={9} lg={8}>
                                <TextField
                                    multiline
                                    fullWidth
                                    rows={4}
                                    id="custom_css"
                                    name="custom_css"
                                    value={values.custom_css || ''}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                            </Grid>
                        </Grid>

                        <TwoColumnsSwitch
                            fieldName="is_attachments_enabled"
                            setFieldValue={setFieldValue}
                            value={values.is_attachments_enabled}
                            label="Разрешить вложения при онлайн записи"
                            labelDecoration={
                                <InfoTooltip text="Включите, чтобы разрешить клиентам загрузать изображения при онлайн записи." />
                            }
                        />

                        <Grid item xs={12}>
                            <Grid container alignItems="center">
                                <Grid item xs={12} sm={3} lg={4}>
                                    <LabelWithInfo
                                        label="Доп. информация в подтверждении"
                                        infoText="Глобальная настройка для доп. информации, посылаемой клиенту в email с подтверждением записи. Можно переопределить для конкретных услуг на странице редактирования услуги."
                                    />
                                </Grid>
                                <Grid item xs={12} sm={9} lg={3} xl={2}>
                                    <FormControl fullWidth>
                                        <OptimizedTextField
                                            multiline
                                            id="confirmation_note"
                                            name="confirmation_note"
                                            value={values?.confirmation_note || null}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            variant="outlined"
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <Grid container alignItems="center">
                                <Grid item xs={12} sm={3} lg={4}>
                                    <LabelWithInfo
                                        label="Ограничение времени до записи в днях"
                                        infoText="Насколько далеко в будущее можно сделать запись через виджет."
                                    />
                                </Grid>
                                <Grid item xs={12} sm={9} lg={3} xl={2}>
                                    <FormControl fullWidth>
                                        <OptimizedTextField
                                            type="number"
                                            id="max_advance_booking"
                                            name="max_advance_booking"
                                            value={values?.max_advance_booking}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            variant="outlined"
                                            InputProps={{
                                                endAdornment: (
                                                    <>
                                                        <InputAdornment position="end">&quot;0&quot; - нет ограничений</InputAdornment>
                                                    </>
                                                )
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <Grid container alignItems="center">
                                <Grid item xs={12} sm={3} lg={4}>
                                    <LabelWithInfo
                                        label="Текст для депозита"
                                        infoText="Отображается на этапе оплаты, в случае когда услуга требует депозита для бронирования"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={9} lg={8}>
                                    <FormControl fullWidth>
                                        <TextEditor
                                            setFieldValue={(val) => setFieldValue('deposit_text', val)}
                                            value={values?.deposit_text ?? ''}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <Box mt={2} mb={3}>
                                <Divider />
                            </Box>
                            <WidgetLinkBuilder
                                links={values.link_builder}
                                setFieldValue={setFieldValue}
                                company={company}
                                errors={errors.link_builder}
                            />
                            <Box mt={3} mb={2}>
                                <Divider />
                            </Box>
                        </Grid>

                        <Grid item xs={12}>
                            <WidgetStyleSettings
                                values={values}
                                handleChange={handleChange}
                                touched={touched}
                                errors={errors}
                                setFieldValue={setFieldValue}
                            />
                        </Grid>
                    </Grid>
                </CardContent>
                <Divider />
                <CardActions>
                    <LoadingButton
                        loading={isLoading}
                        loadingPosition="start"
                        type="submit"
                        color="primary"
                        startIcon={<SaveOutlined />}
                        variant="contained"
                    >
                        Сохранить
                    </LoadingButton>
                </CardActions>
            </form>
        </MainCard>
    );
};

export default WidgetSettingsForm;
