import { FC, useEffect, useMemo } from 'react';
import { CircularProgress, Link, Stack } from '@mui/material';
import { IConversationDetails } from '../../models/IAppointment';
import { Box, CardContent, Grid, IconButton, Typography } from '@material-ui/core';
import CallTwoToneIcon from '@material-ui/icons/CallTwoTone';
import PerfectScrollbar from 'react-perfect-scrollbar';
import ChartHistory from './ChartHistory';
import { makeStyles } from '@material-ui/styles';
import { format } from 'date-fns';
import { useAppSelector } from '../../hooks/redux';
import ChatInput from './ChatInput';
import useMessagesCache from '../../hooks/use-messages-cache';

type ChatConversationProps = {
    conversation?: IConversationDetails | null;
    isLoading?: boolean;
};

// style constant
const useStyles = makeStyles(() => ({
    ScrollHeight: {
        width: '100%',
        flexGrow: 1,
        overflowX: 'hidden'
    }
}));

const ChatConversation: FC<ChatConversationProps> = ({ conversation, isLoading }) => {
    const { selectedEvent } = useAppSelector((store) => store.calendar);
    const { updateMessagesCache } = useMessagesCache();

    const classes = useStyles();
    const threads = useMemo(() => {
        if (conversation) {
            return [...conversation.threads].sort((prev, next) => {
                const prevTime = new Date(prev.created_at).valueOf();
                const nextTime = new Date(next.created_at).valueOf();

                return prevTime - nextTime;
            });
        }
        return [];
    }, [conversation]);

    const customerPhone = useMemo(() => {
        if (selectedEvent) {
            return selectedEvent.customer.phone;
        }

        return null;
    }, [selectedEvent]);

    const appointmentId = selectedEvent?.id;
    const conversationId = conversation?.id;

    useEffect(() => {
        const callback = (
            payload: {
                conversation?: IConversationDetails | null;
            } | null
        ) => {
            if (payload?.conversation && appointmentId) {
                updateMessagesCache(appointmentId, payload.conversation);
            }
        };

        // Subscribe to conversation updated event from Inbox
        window.Echo.private(`App.Appointment.Messaging.Conversation.${conversationId}`).listen(
            '.appointment.messaging.conversation.updated',
            callback
        );

        return () => {
            // Unsubscribe from conversation updated event
            window.Echo.private(`App.Appointment.Messaging.Conversation.${conversationId}`).stopListening(
                '.appointment.messaging.conversation.updated'
            );
            window.Echo.leave(`App.Appointment.Messaging.Conversation.${conversationId}`);
        };
    }, [appointmentId, conversationId, updateMessagesCache]);

    if (isLoading) {
        return (
            <Box sx={{ flexGrow: 1, height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <CircularProgress />
            </Box>
        );
    }

    if (conversation) {
        return (
            <Stack spacing={1} sx={{ flexGrow: 1 }}>
                <Box sx={{ px: 1 }}>
                    <Grid container alignItems="center" spacing={0.5}>
                        <Grid item>
                            <Typography variant="h4" component="div">
                                {conversation.customer.firstname} {conversation.customer.lastname}
                            </Typography>
                            <Typography variant="subtitle2">{`Last message: ${format(
                                new Date(conversation.customer_waiting_since.time),
                                'h:mm a, MM/dd/yyyy'
                            )}`}</Typography>
                        </Grid>
                        <Grid item sm zeroMinWidth />
                        {customerPhone && (
                            <Grid item>
                                <IconButton size="small" component={Link} href={`tel:${customerPhone}`}>
                                    <CallTwoToneIcon />
                                </IconButton>
                            </Grid>
                        )}
                    </Grid>
                </Box>

                <PerfectScrollbar className={classes.ScrollHeight}>
                    <CardContent>
                        <ChartHistory data={threads} />
                    </CardContent>
                </PerfectScrollbar>

                <ChatInput conversationId={conversation.id} />
            </Stack>
        );
    }

    return null;
};

export default ChatConversation;
