import useAuth from '../hooks/useAuth';
import CreateButton from './CreateButton';
import Can from '../utils/roles/Can';
// import usePlanName from '../hooks/usePlanName';

const CreateServiceButton = () => {
    const { user } = useAuth();
    // const planName = usePlanName();

    return (
        <Can I="create" a="service">
            <CreateButton
                user={user}
                maxCountReachedText="Пожалуйста обратитесь в поддержку чтобы повысить план и создать больше локаций, сотрудников или услуг."
                tooltipText="Добавить услугу"
                propertyName="services"
                action="/service/create"
            />
        </Can>
    );
};

export default CreateServiceButton;
