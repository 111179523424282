import { useEffect } from 'react';
import useShowSnackbar from '../../../hooks/useShowSnackbar';
import { SnackBarTypes } from '../../../store/snackbarReducer';
import { useNavigate } from 'react-router-dom';

const SmsPaymentSuccess = () => {
    const { showSnackbar } = useShowSnackbar();
    const navigate = useNavigate();

    useEffect(() => {
        showSnackbar({
            alertSeverity: SnackBarTypes.Success,
            message: 'Пакет СМС оплачен, баланс обновится через несколько минут.'
        });
        navigate('/');
    }, [navigate, showSnackbar]);

    return <></>;
};

export default SmsPaymentSuccess;
