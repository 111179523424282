import { IconButton } from '@material-ui/core';
import EditTwoToneIcon from '@material-ui/icons/EditTwoTone';
import { Link } from 'react-router-dom';
import React from 'react';

interface EditIconButtonProps {
    to: string;
}

const EditIconButton = React.forwardRef(({ to }: EditIconButtonProps) => (
    <Link to={to}>
        <IconButton color="primary">
            <EditTwoToneIcon sx={{ fontSize: '1.3rem' }} />
        </IconButton>
    </Link>
));

export default EditIconButton;
