import { FC } from 'react';
import { FormikProps } from 'formik';
import { RegistrationFormType } from '../types';
import { Grid, MenuItem, TextField } from '@mui/material';

type CompanyDataSubformPropsType = {
    formInstance: FormikProps<RegistrationFormType>;
};

const addressFields = ['address', 'city', 'postal_code', 'country'] as const;
const translations = {
    address: 'Улица, дом, № офиса/квартиры',
    city: 'Город',
    postal_code: 'Почтовый индекс',
    country: 'Страна'
} as const;

const CompanyDataSubform: FC<CompanyDataSubformPropsType> = ({ formInstance }) => {
    const { values, errors, touched, handleChange, handleBlur, setFieldValue } = formInstance;

    return (
        <>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    id="company_name"
                    name="company_name"
                    label="Название компании"
                    value={values.company_name}
                    error={!!errors.company_name && !!touched.company_name}
                    helperText={touched.company_name ? errors.company_name : undefined}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    id="industry"
                    name="industry"
                    label="Индустрия"
                    select
                    value={values.industry}
                    onBlur={handleBlur}
                    error={!!errors.industry && !!touched.industry}
                    helperText={touched.industry ? errors.industry : undefined}
                    onChange={(e) => setFieldValue('industry', e.target.value)}
                >
                    <MenuItem value="barber">Барбершоп</MenuItem>
                    <MenuItem value="med_spa">Спа</MenuItem>
                    <MenuItem value="tattoo">Тату Салон</MenuItem>
                    <MenuItem value="fitness">Фитнес Центр</MenuItem>
                    <MenuItem value="other">Другое</MenuItem>
                </TextField>
            </Grid>
            {addressFields.map((field) => (
                <Grid item xs={12} sm={6} key={field}>
                    <TextField
                        id={`address.${field}`}
                        name={`address.${field}`}
                        label={translations[field]}
                        value={values.address[field]}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        variant="outlined"
                        error={!!touched.address?.[field] && !!errors.address?.[field]}
                        helperText={touched.address?.[field] ? errors.address?.[field] : undefined}
                        fullWidth
                    />
                </Grid>
            ))}
        </>
    );
};

export default CompanyDataSubform;
